import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Typography,
} from '@mui/material';



export default function ManualImg({ url, caption, alt }) {
  return (
    <Box sx={{
      backgroundColor: 'white',
      border: '1px solid rgb(180,180,180)',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      width: '80%',
      maxWidth: 500,
      margin: '15px auto',
      overflow: 'hidden'
    }}>
      <img src={url} alt={alt} style={{ width: '100%' }} />
      <Typography variant='caption'>{caption}</Typography>
    </Box>
  );
}

ManualImg.propTypes = {
  url: PropTypes.string,
  caption: PropTypes.string,
  alt: PropTypes.string
};