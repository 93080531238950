import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';


export default function OptionsPanel({ children }) {
  return (
    <Box>
      <Typography variant='h3' color='black'>Chart Options</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 2
      }}>
        {children}
      </Box>
    </Box>
  );
}



OptionsPanel.propTypes = {
  children: PropTypes.element
};