import React from 'react';

const styles = {
  footerCont: {
    color: 'white',
    padding: '8px',
    backgroundColor: 'rgb(0,60,0)',
    borderTop: '2px solid rgb(248,248,248)'
  },
  footerText: {
    width: 'fit-content',
    margin: '0 auto',
    fontSize: '12px',
    color: 'white'
  },
  footerSubText: {
    width: 'fit-content',
    margin: '0 auto',
    fontSize: '10px',
    color: 'white'
  },
  contact: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: 'bold'
  }
};

export default function Footer() {
  return (
    <footer style={styles.footerCont}>
      <div style={styles.footerText}>Questions or Concerns? <a href='mailto:gollands@cornell.edu?subject=Attn:%20Forages.org%20query' style={styles.contact}>Contact us</a></div>
      {/* <div style={styles.footerSubText}>&copy;Organization. All rights reserved.</div> */}
    </footer>
  );
}