export const orchardTable = {
  '16': { '2':0, '3':0.13840519875, '4':0.2768103975, '5':0.421365161, '6':0.5659199245, '7':0.68642102045, '8':0.8069221164, '9':0.8846284503, '10':0.9623347842},
  '17': { '2':0, '3':0.129574842625, '4':0.25914968525, '5':0.3952374288125, '6':0.531325172375, '7':0.6463478876375, '8':0.7613706029, '9':0.8388072053, '10':0.9162438077},
  '18': { '2':0, '3':0.12122750025, '4':0.2424550005, '5':0.370498416, '6':0.4985418315, '7':0.60832362795, '8':0.7181054244, '9':0.79515302405, '10':0.8722006237},
  '19': { '2':0, '3':0.113363171625, '4':0.22672634325, '5':0.3471481225625, '6':0.467569901875, '7':0.5723482413875, '8':0.6771265809, '9':0.75366590655, '10':0.8302052322},
  '20': { '2':0, '3':0.10598185675, '4':0.2119637135, '5':0.3251865485, '6':0.4384093835, '7':0.53842172795, '8':0.6384340724, '9':0.7143458528, '10':0.7902576332},
  '21': { '2':0, '3':0.099083555625, '4':0.19816711125, '5':0.3046136938125, '6':0.411060276375, '7':0.5065440876375, '8':0.6020278989, '9':0.6771928628, '10':0.7523578267},
  '22': { '2':0, '3':0.09266826825, '4':0.1853365365, '5':0.2854295585, '6':0.3855225805, '7':0.47671532045, '8':0.5679080604, '9':0.64220693655, '10':0.7165058127},
  '23': { '2':0, '3':0.086735994625, '4':0.17347198925, '5':0.2676341425625, '6':0.361796295875, '7':0.4489354263875, '8':0.5360745569, '9':0.60938807405, '10':0.6827015912},
  '24': { '2':0, '3':0.08128673475, '4':0.1625734695, '5':0.251227446, '6':0.3398814225, '7':0.42320440545, '8':0.5065273884, '9':0.5787362753, '10':0.6509451622},
  '25': { '2':0, '3':0.076320488625, '4':0.15264097725, '5':0.2362094688125, '6':0.319777960375, '7':0.3995222576375, '8':0.4792665549, '9':0.5502515403, '10':0.6212365257},
  '26': { '2':0, '3':0.07183725625, '4':0.1436745125, '5':0.222580211, '6':0.3014859095, '7':0.37788898295, '8':0.4542920564, '9':0.52393386905, '10':0.5935756817},
  '27': { '2':0, '3':0.067837037625, '4':0.13567407525, '5':0.2103396725625, '6':0.285005269875, '7':0.3583045813875, '8':0.4316038929, '9':0.49978326155, '10':0.5679626302},
  '28': { '2':0, '3':0.06431983275, '4':0.1286396655, '5':0.1994878535, '6':0.2703360415, '7':0.34076905295, '8':0.4112020644, '9':0.4777997178, '10':0.5443973712},
  '29': { '2':0, '3':0.061285641625, '4':0.12257128325, '5':0.1900247538125, '6':0.257478224375, '7':0.3252823976375, '8':0.3930865709, '9':0.4579832378, '10':0.5228799047},
  '30': { '2':0, '3':0.05873446425, '4':0.1174689285, '5':0.1819503735, '6':0.2464318185, '7':0.31184461545, '8':0.3772574124, '9':0.44033382155, '10':0.5034102307},
  '31': { '2':0, '3':0.056666300625, '4':0.11333260125, '5':0.1752647125625, '6':0.237196823875, '7':0.3004557063875, '8':0.3637145889, '9':0.42485146905, '10':0.4859883492},
  '32': { '2':0, '3':0.05508115075, '4':0.1101623015, '5':0.169967771, '6':0.2297732405, '7':0.29111567045, '8':0.3524581004, '9':0.4115361803, '10':0.4706142602},
  '33': { '2':0, '3':0.053979014625, '4':0.10795802925, '5':0.1660595488125, '6':0.224161068375, '7':0.2838245076375, '8':0.3434879469, '9':0.4003879553, '10':0.4572879637},
  '34': { '2':0, '3':0.05335989225, '4':0.1067197845, '5':0.163540046, '6':0.2203603075, '7':0.27858221795, '8':0.3368041284, '9':0.39140679405, '10':0.4460094597},
  '35': { '2':0, '3':0.053223783625, '4':0.10644756725, '5':0.1624092625625, '6':0.218370957875, '7':0.2753888013875, '8':0.3324066449, '9':0.38459269655, '10':0.4367787482}
};

export const reedTable = {
  '16': { '2':0, '3':0.10474908815, '4':0.2094981763, '5':0.33355086315, '6':0.45760355, '7':0.5720479738, '8':0.6864923976, '9':0.7688732384, '10':0.8512540792},
  '17': { '2':0, '3':0.1004328669625, '4':0.200865733925, '5':0.3196238762125, '6':0.4383820185, '7':0.5489954391125, '8':0.659608859725, '9':0.7414872620875, '10':0.82336566445},
  '18': { '2':0, '3':0.09626524265, '4':0.1925304853, '5':0.30615975615, '6':0.419789027, '7':0.5266336663, '8':0.6334783056, '9':0.7147327214, '10':0.7959871372},
  '19': { '2':0, '3':0.0922462152125, '4':0.184492430425, '5':0.2931585029625, '6':0.4018245755, '7':0.5049626553625, '8':0.608100735225, '9':0.6886096163375, '10':0.76911849745},
  '20': { '2':0, '3':0.08837578465, '4':0.1767515693, '5':0.28062011665, '6':0.384488664, '7':0.4839824063, '8':0.5834761486, '9':0.6631179469, '10':0.7427597452},
  '21': { '2':0, '3':0.0846539509625, '4':0.169307901925, '5':0.2685445972125, '6':0.3677812925, '7':0.4636929191125, '8':0.559604545725, '9':0.6382577130875, '10':0.71691088045},
  '22': { '2':0, '3':0.08108071415, '4':0.1621614283, '5':0.25693194465, '6':0.351702461, '7':0.4440941938, '8':0.5364859266, '9':0.6140289149, '10':0.6915719032},
  '23': { '2':0, '3':0.0776560742125, '4':0.155312148425, '5':0.2457821589625, '6':0.3362521695, '7':0.4251862303625, '8':0.514120291225, '9':0.5904315523375, '10':0.66674281345},
  '24': { '2':0, '3':0.07438003115, '4':0.1487600623, '5':0.23509524015, '6':0.321430418, '7':0.4069690288, '8':0.4925076396, '9':0.5674656254, '10':0.6424236112},
  '25': { '2':0, '3':0.0712525849625, '4':0.142505169925, '5':0.2248711882125, '6':0.3072372065, '7':0.3894425891125, '8':0.471647971725, '9':0.5451311340875, '10':0.61861429645},
  '26': { '2':0, '3':0.06827373565, '4':0.1365474713, '5':0.21511000315, '6':0.293672535, '7':0.3726069113, '8':0.4515412876, '9':0.5234280784, '10':0.5953148692},
  '27': { '2':0, '3':0.0654434832125, '4':0.130886966425, '5':0.2058116849625, '6':0.2807364035, '7':0.3564619953625, '8':0.432187587225, '9':0.5023564583375, '10':0.57252532945},
  '28': { '2':0, '3':0.06276182765, '4':0.1255236553, '5':0.19697623365, '6':0.268428812, '7':0.3410078413, '8':0.4135868706, '9':0.4819162739, '10':0.5502456772},
  '29': { '2':0, '3':0.0602287689625, '4':0.120457537925, '5':0.1886036492125, '6':0.2567497605, '7':0.3262444491125, '8':0.395739137725, '9':0.4621075250875, '10':0.52847591245},
  '30': { '2':0, '3':0.05784430715, '4':0.1156886143, '5':0.18069393165, '6':0.245699249, '7':0.3121718188, '8':0.3786443886, '9':0.4429302119, '10':0.5072160352},
  '31': { '2':0, '3':0.0556084422125, '4':0.111216884425, '5':0.1732470809625, '6':0.2352772775, '7':0.2987899503625, '8':0.362302623225, '9':0.4243843343375, '10':0.48646604545},
  '32': { '2':0, '3':0.05352117415, '4':0.1070423483, '5':0.16626309715, '6':0.225483846, '7':0.2860988438, '8':0.3467138416, '9':0.4064698924, '10':0.4662259432},
  '33': { '2':0, '3':0.0515825029625, '4':0.103165005925, '5':0.1597419802125, '6':0.2163189545, '7':0.2740984991125, '8':0.331878043725, '9':0.3891868860875, '10':0.44649572845},
  '34': { '2':0, '3':0.04979242865, '4':0.0995848573, '5':0.15368373015, '6':0.207782603, '7':0.2627889163, '8':0.3177952296, '9':0.3725353154, '10':0.4272754012},
  '35': { '2':0, '3':0.0481509512125, '4':0.096301902425, '5':0.1480883469625, '6':0.1998747915, '7':0.2521700953625, '8':0.304465399225, '9':0.3565151803375, '10':0.40856496145}
};

export const timothyTable = {
  '16': { '2':0, '3':0.14945155285, '4':0.2989031057, '5':0.44358738985, '6':0.588271674, '7':0.70072626785, '8':0.8131808617, '9':0.885602013, '10':0.9580231643},
  '17': { '2':0, '3':0.1398017122875, '4':0.279603424575, '5':0.417129492975, '6':0.554655561375, '7':0.6643695379125, '8':0.77408351445, '9':0.8476512085, '10':0.92121890255},
  '18': { '2':0, '3':0.1307430711, '4':0.2614861422, '5':0.39217003985, '6':0.5228539375, '7':0.62983230735, '8':0.7368106772, '9':0.81130066275, '10':0.8857906483},
  '19': { '2':0, '3':0.1222756292875, '4':0.244551258575, '5':0.368709030475, '6':0.492866802375, '7':0.5971145761625, '8':0.70136234995, '9':0.77655037575, '10':0.85173840155},
  '20': { '2':0, '3':0.11439938685, '4':0.2287987737, '5':0.34674646485, '6':0.464694156, '7':0.56621634435, '8':0.6677385327, '9':0.7434003475, '10':0.8190621623},
  '21': { '2':0, '3':0.1071143437875, '4':0.214228687575, '5':0.326282342975, '6':0.438335998375, '7':0.5371376119125, '8':0.63593922545, '9':0.711850578, '10':0.78776193055},
  '22': { '2':0, '3':0.1004205001, '4':0.2008410002, '5':0.30731666485, '6':0.4137923295, '7':0.50987837885, '8':0.6059644282, '9':0.68190106725, '10':0.7578377063},
  '23': { '2':0, '3':0.0943178557875, '4':0.188635711575, '5':0.289849430475, '6':0.391063149375, '7':0.4844386451625, '8':0.57781414095, '9':0.65355181525, '10':0.72928948955},
  '24': { '2':0, '3':0.08880641085, '4':0.1776128217, '5':0.27388063985, '6':0.370148458, '7':0.46081841085, '8':0.5514883637, '9':0.626802822, '10':0.7021172803},
  '25': { '2':0, '3':0.0838861652875, '4':0.167772330575, '5':0.259410292975, '6':0.351048255375, '7':0.4390176759125, '8':0.52698709645, '9':0.6016540875, '10':0.67632107855},
  '26': { '2':0, '3':0.0795571191, '4':0.1591142382, '5':0.24643838985, '6':0.3337625415, '7':0.41903644035, '8':0.5043103392, '9':0.57810561175, '10':0.6519008843},
  '27': { '2':0, '3':0.0758192722875, '4':0.151638544575, '5':0.234964930475, '6':0.318291316375, '7':0.4008747041625, '8':0.48345809195, '9':0.55615739475, '10':0.62885669755},
  '28': { '2':0, '3':0.07267262485, '4':0.1453452497, '5':0.22498991485, '6':0.30463458, '7':0.38453246735, '8':0.4644303547, '9':0.5358094365, '10':0.6071885183},
  '29': { '2':0, '3':0.0701171767875, '4':0.140234353575, '5':0.216513342975, '6':0.292792332375, '7':0.3700097299125, '8':0.44722712745, '9':0.517061737, '10':0.58689634655},
  '30': { '2':0, '3':0.0681529281, '4':0.1363058562, '5':0.20953521485, '6':0.2827645735, '7':0.35730649185, '8':0.4318484102, '9':0.49991429625, '10':0.5679801823},
  '31': { '2':0, '3':0.0667798787875, '4':0.133559757575, '5':0.204055530475, '6':0.274551303375, '7':0.3464227531625, '8':0.41829420295, '9':0.48436711425, '10':0.55044002555},
  '32': { '2':0, '3':0.06599802885, '4':0.1319960577, '5':0.20007428985, '6':0.268152522, '7':0.33735851385, '8':0.4065645057, '9':0.470420191, '10':0.5342758763},
  '33': { '2':0, '3':0.0658073782875, '4':0.131614756575, '5':0.197591492975, '6':0.263568229375, '7':0.3301137739125, '8':0.39665931845, '9':0.4580735265, '10':0.51948773455},
  '34': { '2':0, '3':0.0662079271, '4':0.1324158542, '5':0.19660713985, '6':0.2607984255, '7':0.32468853335, '8':0.3885786412, '9':0.44732712075, '10':0.5060756003},
  '35': { '2':0, '3':0.0671996752875, '4':0.134399350575, '5':0.197121230475, '6':0.259843110375, '7':0.3210827921625, '8':0.38232247395, '9':0.43818097375, '10':0.49403947355}
};

export const alfalfaTable = {
  '16': { '2':0, '3':0.0602064000, '4':0.1204128000, '5':0.1899296000, '6':0.2594464000, '7':0.3285824000, '8':0.3977184000, '9':0.4662648000, '10':0.5348112000},
  '17': { '2':0, '3':0.0578539594, '4':0.1157079188, '5':0.1813714781, '6':0.2470350375, '7':0.3123339125, '8':0.3776327875, '9':0.4426320125, '10':0.5076312375},
  '18': { '2':0, '3':0.0556022875, '4':0.1112045750, '5':0.1732232125, '6':0.2352418500, '7':0.2969111000, '8':0.3585803500, '9':0.4202107000, '10':0.4818410500},
  '19': { '2':0, '3':0.0534513844, '4':0.1069027688, '5':0.1654848031, '6':0.2240668375, '7':0.2823139625, '8':0.3405610875, '9':0.3990008625, '10':0.4574406375},
  '20': { '2':0, '3':0.0514012500, '4':0.1028025000, '5':0.1581562500, '6':0.2135100000, '7':0.2685425000, '8':0.3235750000, '9':0.3790025000, '10':0.4344300000},
  '21': { '2':0, '3':0.0494518844, '4':0.0989037688, '5':0.1512375531, '6':0.2035713375, '7':0.2555967125, '8':0.3076220875, '9':0.3602156125, '10':0.4128091375},
  '22': { '2':0, '3':0.0476032875, '4':0.0952065750, '5':0.1447287125, '6':0.1942508500, '7':0.2434766000, '8':0.2927023500, '9':0.3426402000, '10':0.3925780500},
  '23': { '2':0, '3':0.0458554594, '4':0.0917109188, '5':0.1386297281, '6':0.1855485375, '7':0.2321821625, '8':0.2788157875, '9':0.3262762625, '10':0.3737367375},
  '24': { '2':0, '3':0.0442084000, '4':0.0884168000, '5':0.1329406000, '6':0.1774644000, '7':0.2217134000, '8':0.2659624000, '9':0.3111238000, '10':0.3562852000},
  '25': { '2':0, '3':0.0426621094, '4':0.0853242188, '5':0.1276613281, '6':0.1699984375, '7':0.2120703125, '8':0.2541421875, '9':0.2971828125, '10':0.3402234375},
  '26': { '2':0, '3':0.0412165875, '4':0.0824331750, '5':0.1227919125, '6':0.1631506500, '7':0.2032529000, '8':0.2433551500, '9':0.2844533000, '10':0.3255514500},
  '27': { '2':0, '3':0.0398718344, '4':0.0797436688, '5':0.1183323531, '6':0.1569210375, '7':0.1952611625, '8':0.2336012875, '9':0.2729352625, '10':0.3122692375},
  '28': { '2':0, '3':0.0386278500, '4':0.0772557000, '5':0.1142826500, '6':0.1513096000, '7':0.1880951000, '8':0.2248806000, '9':0.2626287000, '10':0.3003768000},
  '29': { '2':0, '3':0.0374846344, '4':0.0749692688, '5':0.1106428031, '6':0.1463163375, '7':0.1817547125, '8':0.2171930875, '9':0.2535336125, '10':0.2898741375},
  '30': { '2':0, '3':0.0364421875, '4':0.0728843750, '5':0.1074128125, '6':0.1419412500, '7':0.1762400000, '8':0.2105387500, '9':0.2456500000, '10':0.2807612500},
  '31': { '2':0, '3':0.0355005094, '4':0.0710010188, '5':0.1045926781, '6':0.1381843375, '7':0.1715509625, '8':0.2049175875, '9':0.2389778625, '10':0.2730381375},
  '32': { '2':0, '3':0.0346596000, '4':0.0693192000, '5':0.1021824000, '6':0.1350456000, '7':0.1676876000, '8':0.2003296000, '9':0.2335172000, '10':0.2667048000},
  '33': { '2':0, '3':0.0339194594, '4':0.0678389188, '5':0.1001819781, '6':0.1325250375, '7':0.1646499125, '8':0.1967747875, '9':0.2292680125, '10':0.2617612375},
  '34': { '2':0, '3':0.0332800875, '4':0.0665601750, '5':0.0985914125, '6':0.1306226500, '7':0.1624379000, '8':0.1942531500, '9':0.2262303000, '10':0.2582074500},
  '35': { '2':0, '3':0.0327414844, '4':0.0654829688, '5':0.0974107031, '6':0.1293384375, '7':0.1610515625, '8':0.1927646875, '9':0.2244040625, '10':0.2560434375},
  '36': { '2':0, '3':0.0323036500, '4':0.0646073000, '5':0.0966398500, '6':0.1286724000, '7':0.1604909000, '8':0.1923094000, '9':0.2237893000, '10':0.2552692000},
  '37': { '2':0, '3':0.0323036500, '4':0.0646073000, '5':0.0966398500, '6':0.1286724000, '7':0.1604909000, '8':0.1923094000, '9':0.2237893000, '10':0.2552692000},
  '38': { '2':0, '3':0.0323036500, '4':0.0646073000, '5':0.0966398500, '6':0.1286724000, '7':0.1604909000, '8':0.1923094000, '9':0.2237893000, '10':0.2552692000},
  '39': { '2':0, '3':0.0323036500, '4':0.0646073000, '5':0.0966398500, '6':0.1286724000, '7':0.1604909000, '8':0.1923094000, '9':0.2237893000, '10':0.2552692000},
  '40': { '2':0, '3':0.0323036500, '4':0.0646073000, '5':0.0966398500, '6':0.1286724000, '7':0.1604909000, '8':0.1923094000, '9':0.2237893000, '10':0.2552692000}
};