import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Welcome from './Components/Welcome';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Nav from './Components/Nav';
import GMT1 from './Components/GMT1';
import GMT2 from './Components/GMT2';
import GMT3 from './Components/GMT3';
import GISResources from './Components/GISResources';

import updateGMT1GraphData from './Functions/gmt1Calcs';
import updateGMT2GraphData from './Functions/gmt2Calcs';
import updateGMT3GraphData from './Functions/gmt3Calcs';
import GRFactsheets from './Components/GRFactsheets';
import Manual from './Components/GMFDC/Manual';

const style = {
  app: {
    width: '100vw',
    minWidth: '600px',
  },
  mainContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 138px)',
    overflowY: 'auto',
    backgroundColor: 'rgb(248,248,248)',
    boxSizing: 'border-box',
    padding: '25px 0px',
  },
};

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2xkdDY4N293MXk2ZzNvbzQydjdhMzVlNSJ9.cAJLgi9kfsBNELg40bssFw';

export default function App() {
  const [options, setOptions] = useState(() => {
    let newOpts = {
      gmt1: {
        species: 'timothy',
        currCanopyHeight: '30',
        plannedStubbleHeight: '10',
        targetNDF: '55',
        estBaseYield: '3200',
      },
      gmt2: {
        currCanopyHeight: '19',
        percentGrass: '0',
        targetNDF: '38',
        weatherConditions: 0,
        maturationRate: '0.65',
        manualMode: false,
        bestOutlook: null,
      },
      gmt3: {
        currCanopyHeight: '37',
        plannedStubbleHeight: '10',
        targetNDF: '32',
        estBaseYield: '1000',
      },
    };

    return {
      gmt1: {
        ...newOpts.gmt1,
        ...updateGMT1GraphData(newOpts.gmt1),
      },
      gmt2: {
        ...newOpts.gmt2,
        ...updateGMT2GraphData(newOpts.gmt2),
      },
      gmt3: {
        ...newOpts.gmt3,
        ...updateGMT3GraphData(newOpts.gmt3),
      },
    };
  });

  const page = useLocation();

  const handleChangeOptions = async (newObj) => {
    let tool;
    if (page.pathname === '/tools/Grass-Only') {
      tool = 'gmt1';
      newObj = {
        ...newObj,
        ...updateGMT1GraphData({ ...options.gmt1, ...newObj }),
      };
    } else if (page.pathname === '/tools/Alfalfa-Grass_Mixture') {
      tool = 'gmt2';
      newObj = {
        ...newObj,
        ...updateGMT2GraphData({ ...options.gmt2, ...newObj }),
      };
    } else if (page.pathname === '/tools/Alfalfa-Only') {
      tool = 'gmt3';
      newObj = {
        ...newObj,
        ...updateGMT3GraphData({ ...options.gmt3, ...newObj }),
      };
    }

    setOptions((prev) => {
      return {
        ...prev,
        [tool]: {
          ...prev[tool],
          ...newObj,
        },
      };
    });
  };

  return (
    <div style={style.app}>
      <Header />

      <Nav />

      <div style={style.mainContent}>
        <Routes>
          <Route path='/' element={<Welcome />} />

          <Route
            path='/tools/Grass-Only'
            element={
              <GMT1
                options={options.gmt1}
                handleChangeOptions={handleChangeOptions}
              />
            }
          />

          <Route
            path='/tools/Alfalfa-Grass_Mixture'
            element={
              <GMT2
                options={options.gmt2}
                handleChangeOptions={handleChangeOptions}
                token={MAPBOX_API_TOKEN}
              />
            }
          />

          <Route
            path='/tools/Alfalfa-Only'
            element={
              <GMT3
                options={options.gmt3}
                handleChangeOptions={handleChangeOptions}
              />
            }
          />

          <Route
            path='/resources/Grass_Information_Sheets'
            element={<GISResources />}
          />

          <Route
            path='/resources/Grass_Management_For_Dairy_Cattle/:chapter'
            element={<Manual />}
          />

          <Route
            path='/resources/Grass-related_Factsheets'
            element={<GRFactsheets />}
          />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

// https://www.cpc.ncep.noaa.gov/products/predictions/long_range/interactive/includes/get_temps_pie.php?coord=38.98970,-76.93776&region=conus&lead=Lead13
// https://www.cpc.ncep.noaa.gov/products/predictions/long_range/lead14/interactive/includes/get_temps_pie.php?coord=42.51,-76.35&region=conus
