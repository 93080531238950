import React from 'react';
import { Box } from '@mui/material';

import NavGroup from './NavGroup';


const menuGroups = [{
  base: '',
  name: 'Home',
  items: []
},{
  base: 'tools',
  name: 'Tools',
  items: [
    { pathname: '/tools/Grass-Only', label: 'Grass-Only Tool' },
    { pathname: '/tools/Alfalfa-Grass_Mixture', label: 'Alfalfa-Grass Mixture Tool' },
    { pathname: '/tools/Alfalfa-Only', label: 'Alfalfa-Only Tool' }
  ]
},{
  base: 'resources',
  name: 'Resources',
  items: [
    { pathname: '/resources/Grass_Management_For_Dairy_Cattle/Preface', label: 'Grass Management For Dairy Cattle' },
    { pathname: '/resources/Grass_Information_Sheets', label: 'Grass Information Sheets' },
    { pathname: '/resources/Grass-related_Factsheets', label: 'Grass-related Factsheets' }
  ]
}];



export default function Nav() {
  return (
    <Box component='nav' sx={{
      backgroundColor: 'rgb(0,150,0)',
      height: 50
    }}>
      <Box component='nav' sx={{ display: 'flex', height: '100%', justifyContent: 'center', gap: '5%', alignItems: 'center' }}>
        { menuGroups.map(group => <Box key={group.name} component={NavGroup} group={group} />) }
      </Box>
    </Box>
  );
}