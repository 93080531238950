export const GMT1_ITEMS_OBJ = {
  label: 'Grass Species',
  items: [
    {
      value: 'orchard',
      text: 'Orchardgrass'
    },
    {
      value: 'reed',
      text: 'Reed Canarygrass'
    },
    {
      value: 'timothy',
      text: 'Timothy'
    }
  ]
};

export const GMT2_ITEMS_OBJ = {
  label: 'Weather Conditions Until Harvest',
  items: [
    {
      value: 'Cool',
      text: 'Cool'
    },
    {
      value: 'Normal',
      text: 'Normal'
    },
    {
      value: 'Hot',
      text: 'Hot'
    }
  ]
};