import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  MenuItem,
  MenuList,
  Typography,
  Popper,
  Grow,
  Paper,
  ListItemText
} from '@mui/material';


const btnSX = {
  position: 'relative',
  color: 'white',
  fontSize: '17px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
  textTransform: 'none',
  height: 35,
  padding: '6px 14px',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.12)'
  }
};



export default function NavGroup({ group }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const hyphenName = group.name.replaceAll(' ', '-');
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveGroup = location.pathname.split('/')[1] === group.base;

  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleLinkClick = (pathname) => {
    if (pathname[0] === '/') {
      navigate(pathname);
      setOpen(false);
    } else {
      window.open(pathname, '_blank')?.focus();
    }
  };


  if (group.name === 'Home') {
    return (
      <Box>
        <Button
          id={`${hyphenName}-button`}
          sx={{
            ...btnSX,
            border: isActiveGroup ? '1px solid gold' : 'none',
            '& .MuiTouchRipple-child': {
              backgroundColor: 'rgb(180,180,180)'
            }
          }}
          onClick={() => handleLinkClick('/')}
        >
          <Typography variant='links'>{group.name}</Typography>
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Popper
        id={`${hyphenName}-menu`}
        open={open}
        anchorEl={anchorEl}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={handleClose}
        placement='bottom'
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top' }}
          >
            <Paper sx={{
              border: '1px solid rgb(242,242,242)'
            }}>
              <MenuList>
                {group.items.map(item => {
                  const isActiveLink = location.pathname === item.pathname;

                  return (
                    <MenuItem key={item.label} onClick={() => handleLinkClick(item.pathname)} sx={{ backgroundColor: isActiveLink ? 'rgba(0,0,0,0.08)' : 'white' }}>
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Button
        disableRipple
        id={`${hyphenName}-button`}
        aria-controls={anchorEl ? `${hyphenName}-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        sx={{ ...btnSX, border: isActiveGroup ? '1px solid gold' : 'none'}}
      >
        <Typography variant='links'>{group.name}</Typography>
      </Button>
    </Box>
  );
}

NavGroup.propTypes = {
  group: PropTypes.object,
};