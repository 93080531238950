import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Switch,
  Box,
  Typography,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/system';

import Address from './Address';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  margin: '3px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(0,175,0)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgb(255,0,0)',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: 'rgb(180,180,180) !important'
  }
}));

const width = 175;

const weatherOpts = [
  ['Cold', -0.15],
  ['Normal', 0],
  ['Hot', 0.15]
];



export default function GMT2Options({ options, handleChangeOptions, token }) {
  const [auto, setAuto] = useState(true);
  const [outlook, setOutlook] = useState(true);
  const [manualWeather, setManualWeather] = useState(0);
  

  const handleSwitch = () => {
    handleChangeOptions({manualMode: auto});
    setAuto(!auto);
  };

  const handleOutlook = () => {
    handleChangeOptions({ weatherConditions: manualWeather });
    setOutlook(!outlook);
  };

  const handleChange = (value) => {
    if (!outlook) {
      handleChangeOptions({ weatherConditions: value });
    }
    setManualWeather(value);
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        marginBottom: '20px'
      }}>
        <Box sx={{
          backgroundColor: 'white',
          height: 56,
          width,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5px',
          alignItems: 'center',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid rgb(230,230,230)',
          borderBottom: '1px solid black',
          '&:hover': {
            backgroundColor: 'rgb(248,248,248)',
          }
        }}>
          <Typography variant='optText'>Manual NDF and Rate</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Typography>Off</Typography>
            <AntSwitch
              checked={!auto}
              onChange={handleSwitch}
            />
            <Typography>On</Typography>
          </Box>
        </Box>

        <Box sx={{
          backgroundColor: 'white',
          height: 56,
          width,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5px',
          alignItems: 'center',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid rgb(230,230,230)',
          borderBottom: '1px solid black',
          '&:hover': {
            backgroundColor: 'rgb(248,248,248)',
          }
        }}>
          <Typography variant='optText'>Weather Outlook</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <Typography>Choose</Typography>
            <AntSwitch
              checked={outlook}
              onChange={handleOutlook}
            />
            <Typography>Lookup</Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center'
      }}>
        <Box>
          <TextField
            label='Current Alfalfa Height'
            type='number'
            value={parseFloat(options.currCanopyHeight)}
            onChange={(e) => handleChangeOptions({currCanopyHeight: String(e.target.value)})}
            inputProps={{min: '15', max: '40', step:'0.5', style: { textAlign: 'center' }}}
            variant='filled'
            sx={{ width }}
          />
        </Box>
        <Box>
          <TextField
            label='Percentage Grass'
            type='number'
            value={parseFloat(options.percentGrass)}
            onChange={(e) => handleChangeOptions({percentGrass: String(e.target.value)})}
            inputProps={{min: '0', max: '100', step:'5', style: { textAlign: 'center' }}}
            variant='filled'
            sx={{ width }}
          />
        </Box>
        <Box>
          <TextField
            label='Target NDF'
            type='number'
            value={parseFloat(options.targetNDF)}
            onChange={(e) => handleChangeOptions({targetNDF: String(e.target.value)})}
            inputProps={{min: '0', max: '100', step:'0.5', style: { textAlign: 'center' }}}
            disabled={auto}
            variant='filled'
            sx={{ width }}
          />
        </Box>
        <Box>
          <TextField
            label='Maturation Rate'
            type='number'
            value={parseFloat(options.maturationRate)}
            onChange={(e) => handleChangeOptions({maturationRate: String(e.target.value)})}
            inputProps={{min: '0.5', max: '1.4', step:'0.05', style: { textAlign: 'center' }}}
            disabled={auto}
            variant='filled'
            sx={{ width }}
          />
        </Box>
      
        {auto && outlook ?
          <Address token={token} handleChangeOptions={handleChangeOptions} />
          :
          <TextField
            select
            label='Upcoming Weather'
            onChange={(e) => {
              let newVal = weatherOpts.find(opt => opt[1] === e.target.value);
              handleChange(newVal[1]);
            }}
            variant='filled'
            sx={{ width }}
            value={manualWeather}
            SelectProps={{
              renderValue: (val) => {
                let arr = weatherOpts.find(opt => opt[1] === val);
                return arr[0];
              }
            }}
          >
            {weatherOpts.map(opt => <MenuItem key={opt[0]} value={opt[1]}>{opt[0]}</MenuItem>)}
          </TextField>
        }
      </Box>
    </Box>
  );
}


GMT2Options.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
  token: PropTypes.string
};