import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';



export default function Title({ title, subtitle }) {
  return (
    <Box sx={{
      margin: '0 auto 20px auto',
      backgroundColor: 'white',
      width: 'fit-content',
      padding: 4,
      border: '1px solid rgb(230,230,230)'
    }}>
      <Typography variant='h1'>{title}</Typography>
      <Typography variant='subtitle1'>{subtitle}</Typography>
    </Box>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};