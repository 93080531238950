import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import ManualNav from './ManualNav';
import ManualContent from './ManualContent';



export default function Manual() {
  let loc = useLocation();
  loc = loc.pathname.split('/')[3].replaceAll('_', ' ');
  
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      gap: '12px',
      boxSizing: 'border-box',
      paddingLeft: '12px',
      paddingRight: '12px'
    }}>
      <ManualNav loc={loc} />

      <Box sx={{ flexGrow: 1 }}>
        <ManualContent chapter={loc} />
      </Box>
    </Box>
  );
}