import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

const fontFamily = '"Merriweather","Roboto","Helvetica","Arial",sans-serif';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 704,
      md: 900,
      lg: 1098,
      xl: 1536,
    },
  },
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff'
    },
    'background': {
      'paper': '#fff',
      'default': 'rgba(242, 242, 242, 1)'
    },
    'primary': {
      'light': '#80e27e',
      'main': '#4caf50',
      'dark': '#087f23',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': '#4c8c4a',
      'main': '#1b5e20',
      'dark': '#003300',
      'contrastText': '#fff'
    },
    'error': {
      'light': 'rgba(244, 147, 54, 1)',
      'main': 'rgba(244, 206, 54, 1)',
      'dark': 'rgba(238, 195, 19, 1)',
      'contrastText': 'rgba(0, 0, 0, 1)'
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  },
  typography: {
    fontFamily,
    h1: {
      fontSize: '2.75rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    h3: {
      fontSize: '1.25rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      paddingLeft: 50
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      paddingLeft: 75
    },
    subtitle1: {
      fontSize: '2rem',
      fontStyle: 'italic',
      textAlign: 'center'
    },
    link: {
      fontSize: '1rem',
      fontWeight: 'bold',
      fontFamily
    },
    optText: {
      fontSize: '12px',
      alignSelf: 'flex-start',
      marginLeft: '10px'
    },
    body3: {
      fontSize: '0.82rem',
      textAlign: 'justify'
    },
    body2: {
      color: 'rgb(80,80,80)',
      fontSize: '0.7rem',
      textIndent: -75,
      marginLeft: 75,
      maxWidth: 'calc(100%- 75px)'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        input: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': 'rgb(180,180,180)'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        },
        input: {
          backgroundColor: 'white',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid rgb(230,230,230)',
          borderBottom: '1px solid black',
          '&:hover': {
            backgroundColor: 'rgba(248,248,248,1)',
          },
          '&:focus': {
            backgroundColor: 'rgba(248,248,248,1)',
          }
        }
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
