import React from 'react';

import { Box } from '@mui/material';

const styles = {
  headerCont: {
    backgroundColor: 'rgb(179,27,27)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    alignItems: 'center',
    borderBottom: '2px solid rgb(80,80,80)'
  },
  cuLogo: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/cu_logo.gif)`,
    height: 37,
    width: 37,
    backgroundPositionY: -4
  },
  left: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content'
  },
  orgInfo: {
    marginLeft: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    fontFamily: '"Times New Roman","serif"',
  },
  orgName: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  toolName: {
    fontSize: '16px',
    fontStyle: 'italic'
  }
};

export default function Header() {
  const navigate = () => {
    window.open('https://forages.org/', '_blank')?.focus();
  };
  
  return (
    <header style={styles.headerCont}>
      <div style={styles.left}>
        <a href='https://www.cornell.edu/' target='_blank' rel='noreferrer'>
          <div style={styles.cuLogo}></div>
        </a>
        <div style={styles.orgInfo}>
          <div style={styles.orgName}>Cornell University</div>
          <div style={styles.toolName}>Grass Management Tools</div>
        </div>
      </div>

      <Box onClick={navigate} sx={{
        backgroundColor: 'rgb(159,7,7)',
        width: 'fit-content',
        borderRadius: '5px',
        marginRight: '16px',
        color: 'white',
        border: 'none',
        fontSize: '13px',
        padding: '8px 12px',
        height: 'fit-content',
        '&:hover': {
          backgroundColor: 'rgb(139,7,7)',
          cursor: 'pointer'
        }
      }}>
        Forage Species Selector Tool
      </Box>
    </header>
  );
}