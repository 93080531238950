import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';



export default function CenterPanel({ children }) {
  return (
    <Box sx={{
      height: 'fit-content',
      width: '85%',
      maxWidth: 1000,
      margin: '30px auto 30px auto',
      padding: '25px 10px',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)',
      backgroundColor: 'white',
      boxSizing: 'border-box'
    }}>
      {children[0]}
      
      <Box sx={{
        backgroundColor: 'rgb(0,150,0)',
        height: '3px',
        width: '75%',
        margin: '20px auto'
      }}></Box>
      
      {children[1]}
    </Box>
  );
}



CenterPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ])
};