/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import PatternFill from 'highcharts/modules/pattern-fill';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
highchartsMore(Highcharts); 
exporting(Highcharts);
HC_offline_exporting(Highcharts);
NoDataToDisplay(Highcharts);
PatternFill(Highcharts);

import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

import roundXDigits from '../Functions/rounding';

function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}



export default function BarGraph({ options }) {
  const theme = useTheme();

  let chartOptions = {
    exporting:{
      fallbackToExportServer: false,
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG']
        }
      }
    },

    chart: {
      type: 'column',
      style: {
        fontFamily: theme.typography.fontFamily
      }
    },

    title: {
      text: ''
    },
    
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatNumber(this.point.y);
          }
        }
      }
    },

    series: [{
      pointWidth: 100,
      data: [
        {
          name: 'Yield',
          y: roundXDigits(options.estBaseYield, 0, true),
          color: {
            pattern: {
              image: `${process.env.PUBLIC_URL}/grass1.svg`
            }
          }
        },
        {
          name: 'Yield',
          y: roundXDigits(options.predictedYield, 0, true),
          color: {
            pattern: {
              image: `${process.env.PUBLIC_URL}/grass2.svg`
            }
          }
        },
        {
          name: 'Yield',
          y: roundXDigits(options.yieldLoss, 0, true),
          color: {
            pattern: {
              image: `${process.env.PUBLIC_URL}/grass3.svg`
            }
          }
        }
      ]
    }],

    xAxis: {
      categories: [
        'Potential yield (2" cut)',
        'Predicted yield',
        'Yield loss'
      ],
      plotLines: [{
        value: -0.5,
        width: 2,
        color: 'black'
      }],
      lineColor: 'black',
      lineWidth: 2,
      tickInterval: 1,
      tickColor: 'black',
      tickWidth: 2,
      labels: {
        style: {
          color: 'black'
        }
      }
    },
    
    yAxis: {
      title: {
        text: 'Alfalfa yield (lb/acre)'
      },
      gridZIndex: 0,
      tickWidth: 2,
      tickColor: 'black',
      labels: {
        style: {
          color: 'black'
        }
      }
    },

    credits: {
      enabled: false
    },

    legend: {
      enabled: false
    },
      
    tooltip: {
      enabled: false
    }
  };


  return (
    <Box sx={{ width: '49%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Box>
  );
}



BarGraph.propTypes = {
  options: PropTypes.object,
};