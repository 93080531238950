const gisFiles = [
  { name: 'GIS-01: Grass for Dairy Cows in the Northeast', filename: 'GIS1_Grass_for_Dairy_Cows_in_the_Northeast.pdf' },
  { name: 'GIS-02: History of Perennial Grasses', filename: 'GIS2_History_of_Perennial_Grasses.pdf' },
  { name: 'GIS-03: Grass and Nutrient Management', filename: 'GIS3_Grass_and_Nutrient_Management.pdf' },
  { name: 'GIS-04: Grass Species Selection in the Northeast', filename: 'GIS4_Grass_Species_Selection_in_the_Northeast.pdf' },
  { name: 'GIS-05: Grass Seeding and Establishment', filename: 'GIS5_Grass_Seeding_and_Establishment.pdf' },
  { name: 'GIS-06: Reed Canarygrass', filename: 'GIS6_Reed_Canarygrass.pdf' },
  { name: 'GIS-07: Tall Fescue', filename: 'GIS7_Tall_Fescue.pdf' },
  { name: 'GIS-08: Orchardgrass', filename: 'GIS8_Orchardgrass.pdf' },
  { name: 'GIS-09: Timothy', filename: 'GIS9_Timothy.pdf' },
  { name: 'GIS-10: Smooth Bromegrass', filename: 'GIS10_Smooth_Bromegrass.pdf' },
  { name: 'GIS-11: Perennial Ryegrass', filename: 'GIS11_Perennial_Ryegrass.pdf' },
  { name: 'GIS-12: Reed Canarygrass Seeding Rate Studies', filename: 'GIS12_Reed_Canarygrass_Seeding_Rate_Studies.pdf' },
  { name: 'GIS-13: Alfalfa-Fescue Establishment Studies', filename: 'GIS13_Alfalfa-Fescue_Establishment_Studies.pdf' },
  { name: 'GIS-14: Orchardgrass-Alfalfa Establishment Studies', filename: 'GIS14_Orchardgrass-Alfalfa_Establishment_Studies.pdf' },
  { name: 'GIS-15: Nitrogen Fertilization of Perennial Grass', filename: 'GIS15_Nitrogen_Fertilization_of_Perennial_Grass.pdf' },
  { name: 'GIS-16: Manure Application to Perennial Grasses', filename: 'GIS16_Manure_Application_to_Perennial_Grasses.pdf' },
  { name: 'GIS-17: Morphological Staging of Perennial Grasses', filename: 'GIS17_Morphological_Staging_of_Perennial_Grasses.pdf' },
  { name: 'GIS-18: Grass Nutritive Value', filename: 'GIS18_Grass_Nutritive_Value.pdf' },
  { name: 'GIS-19: Common Grass-Livestock Disorders', filename: 'GIS19_Common_Grass-Livestock_Disorders.pdf' },
  { name: 'GIS-20: Grass Harvest Management', filename: 'GIS20_Grass_Harvest_Management.pdf' },
  { name: 'GIS-21: Wide Swathing for High Quality Forage', filename: 'GIS21_Wide_Swathing_for_High_Quality_Forage.pdf' },
  { name: 'GIS-22: Predicting Spring Fiber Content of Forages', filename: 'GIS22_Predicting_Spring_Fiber_Content_of_Forages.pdf' },
  { name: 'GIS-23: Grass and Alfalfa Stubble Height Issues', filename: 'GIS23_Grass_and_Alfalfa_Stubble_Height_Issues.pdf' },
  { name: 'GIS-24: Comparing Grass Cultivars in the Spring', filename: 'GIS24_Comparing_Grass_Cultivars_in_the_Spring.pdf' },
  { name: 'GIS-25: Grass for Dry Cows', filename: 'GIS25_Grass_for_Dry_Cows.pdf' },
  { name: 'GIS-26: Corn vs SxS', filename: 'GIS26_Corn_vs_SxS.pdf' },
  { name: 'GIS-27: Small Grains for Supplemental Forage', filename: 'GIS27_Small_Grains_for_Supplemental_Forage.pdf' },
  { name: 'GIS-28: Environmental Stress and Forage Quality', filename: 'GIS28_Environmental_Stress_and_Forage_Quality.pdf' },
  { name: 'GIS-29: Grass Silage', filename: 'GIS29_Grass_Silage.pdf' },
  { name: 'GIS-30: Feeding Grass to Dairy Cows', filename: 'GIS30_Feeding_Grass_to_Dairy_Cows.pdf' },
  { name: 'GIS-31: Feeding Alfalfa-Grass to Dairy Cows', filename: 'GIS31_Feeding_Alfalfa-Grass_to_Dairy_Cows.pdf' },
  { name: 'GIS-32: Precision Feeding and Grasses', filename: 'GIS32_Precision_Feeding_and_Grasses.pdf' },
  { name: 'GIS-33: Feeding BMR Corn Silage', filename: 'GIS33_Feeding_BMR_Corn_Silage.pdf' },
  { name: 'GIS-34: Economics of Feeding Grass', filename: 'GIS34_Economics_of_Feeding_Grass.pdf' },
  { name: 'GIS-35: Grass for Forage Biomass or Bedding', filename: 'GIS35_Grass_for_Forage_Biomass_or_Bedding.pdf' },
  { name: 'GIS-36: Grass and the Environment', filename: 'GIS36_Grass_and_the_Environment.pdf' },
];

export default gisFiles;