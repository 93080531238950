import roundXDigits from './rounding';

function roundHalf(num) {
  return Math.round(num * 2) / 2;
}



export default function updateGMT2GraphData(options) {
  let decGrass = options.percentGrass / 100;
  let currNDF = 8.71 + 0.32 * 2.51 * options.currCanopyHeight + 31.3 * decGrass;

  let bestOutlook = null;
  let maturationRate, targetNDF;
  if (options.manualMode) {
    maturationRate = parseFloat(options.maturationRate);
    targetNDF = parseFloat(options.targetNDF);
  } else {
    targetNDF = roundHalf(0.12 * options.percentGrass + 38);
  
    // If initial load with no address yet, assume normal outlook
    if (typeof options.weatherConditions === 'number') {
      maturationRate = options.weatherConditions + decGrass + ((1 - decGrass) * 0.65);
    } else {
      // Check each outlook and get the best one for the current options
      bestOutlook = options.weatherConditions.reduce((acc,outlook) => {
        if (!acc) {
          let weatherAdjustment, text;
          if (outlook.outlook.above > 45) {
            text = `${outlook.outlook.above}% chance above normal range`;
            weatherAdjustment = 0.15;
          } else if (outlook.outlook.below > 45) {
            text = `${outlook.outlook.below}% chance below normal range`;
            weatherAdjustment = -0.15;
          } else {
            text = `${outlook.outlook.near}% chance within normal range`;
            weatherAdjustment = 0;
          }

          let maturationRate = weatherAdjustment + decGrass + ((1 - decGrass) * 0.65);
          let dth = (targetNDF - currNDF) / maturationRate;
          let dthRounded = roundHalf(dth);

          if (dthRounded < outlook.outlookMeta.rangeEnd || outlook.outlookMeta.rangeEnd === 90) {
            acc = {
              dth,
              dthRounded,
              maturationRate,
              outlookInfo: {
                name: outlook.outlookMeta.name,
                range: `${outlook.normal.min}-${outlook.normal.max}°F`,
                text
              }
            };
          }
        }

        return acc;
      }, false);

      maturationRate = bestOutlook.maturationRate;
    }
  }

  let targetHeight = roundHalf((targetNDF - 8.71 - 31.3 * decGrass) / (0.32 * 2.51));

  let dth = (targetNDF - currNDF) / maturationRate;
  let dthRounded = roundHalf(dth);
  let numDaysOnGraph = Math.ceil(dth);

  if (numDaysOnGraph < 1) numDaysOnGraph = 1;

  let tlData = [];
  let plData = [];
  for (let i = 0; i <= numDaysOnGraph; i+=0.5) {
    tlData.push([i, targetNDF]);
    plData.push([i, currNDF + (maturationRate * i)]);
  }

  return {
    tlData,
    plData,
    targetHeight,
    dthPoint: [dthRounded, targetNDF],
    currNDFPoint: [0, currNDF],
    targetNDF: roundXDigits(targetNDF, 1),
    maturationRate: roundXDigits(maturationRate, 2),
    bestOutlook
  };
}