import React from 'react';

import { Box, Typography, List, ListItem } from '@mui/material';

import gisFiles from '../StaticData/gisResources';



export default function GISResources() {
  return (
    <Box sx={{
      width: 460,
      backgroundColor: 'white',
      margin: '0 auto',
      padding: '25px',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)'
    }}>
      <Typography sx={{ fontSize: '20px' }}>Grass Information Sheets</Typography>
      <List>
        {gisFiles.map(gFile => {
          return (
            <ListItem key={gFile.name}>
              <a style={{ textDecoration: 'none' }} href={`${process.env.PUBLIC_URL}/gis/${gFile.filename}`} target='_blank' rel='noreferrer'>
                <Box sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    fontWeight: 'bold'
                  }
                }}>
                  {gFile.name}
                </Box>
              </a>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}