import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';



export default function GraphPanel({ children }) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      gap: '0.5%',
      width: '99%',
      maxWidth: children instanceof Array ? 1600 : 1000,
      margin: '0 auto',
      paddingTop: '25px',
      paddingBottom: '25px',
      // backgroundColor: 'rgb(126,87,14)',
      backgroundColor: 'white',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)'
    }}>
      {children}
    </Box>
  );
}



GraphPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ])
};