import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';



export default function TextResultsPanel({ children }) {
  return (
    <Box>
      <Typography variant='h3' color='black' sx={{ marginBottom: '20px' }}>Calculation Results</Typography>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
      }}>
        {children}
      </Box>
    </Box>
  );
}

TextResultsPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ])
};