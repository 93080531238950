import { alfalfaTable } from '../StaticData/dmLossTables';
import roundXDigits from './rounding';

function getTargetNDFLine(targetNDF) {
  let numTNDF = parseFloat(targetNDF);
  
  let data = [];
  for (let i = 16; i <= 40; i++) {
    data.push([i, numTNDF]);
  }

  return { targetNDFLineData: data };
}

function getPredictedNDFLine(canopyHeight, stubbleHeight, targetNDF) {
  let fStub = parseFloat(stubbleHeight);
  
  let currNDF = 116 / 10 + 3.59 * parseFloat(canopyHeight) * 0.25 - 3.88 * fStub * 0.25;
  let targetHeight = (parseFloat(targetNDF) - 116 / 10 + 3.88 * fStub * 0.25) / (3.59 * 0.25);

  let currPredictedPoint = [0,0];
  let predictedNDFLineData = [];
  for (let i = 16; i <= 40; i++) {
    let pointY = 116 / 10 + 3.59 * i * 0.25 - 3.88 * fStub * 0.25;
    predictedNDFLineData.push([i, pointY]);

    if (String(i) === canopyHeight) {
      currPredictedPoint = [[i, pointY]];
    }
  }

  return {
    currNDF,
    targetHeight,
    predictedNDFLineData,
    currPredictedPoint
  };
}

function getBarData(targetHeight, plannedStubbleHeight, estBaseYield) {
  let roundedTH = parseInt(roundXDigits(targetHeight, 0));
  if (roundedTH < 16 || roundedTH > 40) {
    return {
      predictedYield: '0',
      yieldLoss: '0'
    };
  }

  let numEBY = parseFloat(estBaseYield);

  let yieldLoss = numEBY * alfalfaTable[roundedTH][plannedStubbleHeight];
  let predictedYield = numEBY - yieldLoss;
  
  return {
    predictedYield: predictedYield,
    yieldLoss: yieldLoss
  };
}

export default function updateGMT3GraphData(options) {
  let plData = getPredictedNDFLine(options.currCanopyHeight, options.plannedStubbleHeight, options.targetNDF);
  let bData = getBarData(plData.targetHeight, options.plannedStubbleHeight, options.estBaseYield);
  let tlData = getTargetNDFLine(options.targetNDF);

  return {
    ...bData,
    ...plData,
    ...tlData
  };
}