import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Typography
} from '@mui/material';



export default function ManualSubsection({ title, content }) {
  return (
    <Box>
      <Typography variant='h5' sx={{ marginTop: '20px' }}>{title}</Typography>

      {
        content.map((c, i) => 
          <Typography
            key={'intro' + i}
            variant='body1'
            dangerouslySetInnerHTML={{__html: c}}
            sx={{
              width: '90%',
              margin: '20px auto 10px auto',
              textAlign: 'justify',
              fontSize: '0.82rem',
              '& a': {
                color: 'rgb(170,0,0)',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          ></Typography>
        )
      }
    </Box>
  );
}

ManualSubsection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array
};