const manualPages = {
  'Preface': {
    chapter: {
      content: [
        'This manual primarily deals with cool-season perennial grasses for the Northeastern USA, including some discussion of a few annual forage grasses. This discussion focuses on grasses adapted to Northeastern USA growing conditions. It also focuses on grass for stored feed rather than for grazing. Numerous grazing manuals and bulletins are available. There is a large acreage of grass grown with alfalfa in the Northeast, so that mixture is also included.',
        `Much of the material presented here is based on field experiments and dairy feeding trials conducted at Cornell University over the past two decades. The varying length of each chapter depends, in part, on the extent of our research on the topic. Many other individuals were involved with these studies besides the authors of this manual, see the <a href="${process.env.PUBLIC_URL}/resources/Grass-Management-For-Dairy-Cattle/References">References</a> listed below for a complete listing of contributors. Existing Factsheets related to this topic are also listed below, and can be found at the Cornell Nutrient Management SPEAR program site at: [<a href="http://nmsp.cals.cornell.edu/guidelines/factsheets.html" target="_blank" rel="noreferrer">http://nmsp.cals.cornell.edu/guidelines/factsheets.html</a>].`
      ],
      img: []
    },
    sections: []
  },
  'Introduction': {
    chapter: {
      content: [`Grass is the most common crop ensiled in the world, followed by corn and alfalfa. Much of the cropland in the Northeast is better suited to perennial grass production than to legumes or row crop production [<a href="${process.env.PUBLIC_URL}/gis/GIS1_Grass_for_Dairy_Cows_in_the_Northeast.pdf" target="_blank" rel="noreferrer">GIS-1</a>]. Grasses also have advantages when it comes to nutrient management. Compared to alfalfa, grasses have a greater response to manure, which can be applied multiple times during the season. Grasses use large quantities of nutrients, minimizing the risk of nutrient leaching or runoff. They can remove over twice the nitrogen per acre compared to corn. Grasses also can increase soil structure and organic matter, enhancing long term productivity [<a href="${process.env.PUBLIC_URL}/gis/GIS3_Grass_and_Nutrient_Management.pdf" target="_blank" rel="noreferrer">GIS-3</a>].`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_1_pic.jpg`,
        caption: 'The Northeast USA is better suited to perennial grass production than to legumes or row crop production.',
        alt: 'Field of grass'
      }]
    },
    sections: [{
      title: 'Grass Species',
      content: [`Cool-season perennial grasses provide a significant portion of the forage for dairy cows in the New York State [<a href="${process.env.PUBLIC_URL}/gis/GIS2_History_of_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-2</a>]. Most of the perennial grasses used are not native to North America, but were introduced from Eurasia or North Africa several centuries ago. Timothy is well adapted to the Northeast environment and remains the most popular cool-season grass sown in NY, but other grasses such as reed canarygrass and tall fescue are increasing in popularity.`],
      subsections: []
    },{
      title: 'Silage vs. Hay',
      content: ['Although it is possible to make hay in the Northeast in the spring, the odds are against it. Overall grass silage quality is considerably higher than for grass hay, based on forage lab analyses, but the average quality of grass silage is not yet where it can and should be. High yields are possible, but some dairy farmers discriminate against grass due to forage quality issues. More legitimate concerns are the economic and environmental costs of N fertilization, and moisture availability, both have strong impacts on grass yield. Significant advances have been made in recent years in recognizing the potential of well-managed grasses, but on average, the level of grass management is still not as high as with alfalfa.'],
      subsections: []
    }]
  },
  'Species Selection': {
    chapter: {
      content: [`In the Northeast, if you don't like your soil, take 100 paces in any direction, you are apt to end up standing on a different soil type (and you may not like it either). Poorly drained soils, acid soils, stony soils, fragipan soils and heavy clay soils outnumber the highly productive soil types. Grass species selection is strongly influenced by soil conditions. Selecting forage species that are best suited to a particular soil type and forage use provides the most efficient land use [<a href="${process.env.PUBLIC_URL}/gis/GIS4_Grass_Species_Selection_in_the_Northeast.pdf" target="_blank" rel="noreferrer">GIS-4</a>]. In NY, species are recommended using a web-based program based on potential yield, intended forage use, and soil type [<a href="http://forages.org" target="_blank" rel="noreferrer">http://forages.org</a>].`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_2_pic.jpg`,
        caption: 'In the Northeast, grass species selection is strongly influenced by soil conditions. Many soils are well suited to reed canarygrass.',
        alt: 'Field of grass'
      }]
    },
    sections: [{
      title: 'Species Selection Program',
      content: [
        'This species selection program was developed to provide recommendations for perennial forage species in New York State (and PA), based on soil type and intended forage use through a web interface. Forage species uses include both pasture and stored feed for different livestock classes, as well as soil conservation uses. A database was developed that contains potential yields for over 30 species and over 40 species mixtures on over 600 agricultural soil types in New York State. The database has potential forage yields based on native soil drainage as well as yields for optimal artificial drainage. Potential yields are adjusted by the program based on soil pH and the optimal pH range for each forage species. Species are recommended based on potential yield, intended forage use and soil type.',
        'The program also provides pasture yield distributions, a summary of species traits and potential corn grain and silage yields for a selected soil type. A subroutine based on basic soil characteristics and location (zip code) allows the program to estimate the soil type, if the soil type is not known. Computer-based species recommendations agree with current published recommendations. Although the soil type - species yield relationships will not apply directly to other regions of the world, the method utilized to develop the database could be used to create similar species selection programs for other regions.'
      ],
      subsections: []
    },{
      title: 'Roundup-Ready Alfalfa in the Northeast',
      content: [
        'Over 80% of alfalfa sown in New York State is sown with a grass companion crop. This differs greatly from the Midwest, where approximately 20% of alfalfa is sown with grass, and from the Western USA, where very little alfalfa is sown with grass. Roundup-Ready (RR)-alfalfa has the potential to significantly reduce the current acreage of mixed seedings of alfalfa and perennial grass in the Midwest and the West. The impact of RR-alfalfa in the Northeast is less clear. RR-alfalfa sown on a marginal site for alfalfa may only result in a clean, thin stand of alfalfa with unsatisfactory yield.',
        'Establishing RR-alfalfa and then interseeding perennial grass into the established alfalfa would allow for good weed control during alfalfa establishment, but does not appear to offset the risk of achieving successful grass introduction into an existing alfalfa stand. Alfalfa cannot spread from existing plants or reseed itself to fill in wet spots, nor can it fill in stands thinned by winter damage. RR-alfalfa is less likely to take over the market in the Northeast, as happened with RR-soybeans. It is more likely that the majority of alfalfa stands in the Northeast will remain as mixed seedings.',
        'Pure alfalfa, particularly very high quality (low NDF) pure alfalfa, can be challenging to use as a sole fiber source in lactating dairy rations. One solution used by some dairy farmers is to include poor quality grass hay or straw in the diet. A better solution is to grow alfalfa mixed with cool-season grasses. Including grass in the diet has consistently resulted in a reduction in animal health problems while maintaining milk production. If a producer is marketing pure alfalfa, and has good land to grow it, RR-alfalfa should be considered. If a producer is feeding forage to lactating dairy cows, an alfalfa-grass mixture may be more appropriate in the Northeast.'
      ],
      subsections: []
    },{
      title: 'Primary Factors to Consider',
      content: [],
      subsections: [{
        title: 'Persistence',
        content: [
          'The Northeast is great for growing grass, but less than ideal soil drainage can be stressful. Fluctuating temperatures and variable snow cover in the winter also can stress overwintering grass. For example, orchardgrass is no longer recommended in parts of the region due to risk of winterkill. Grass species vary in their tolerance of soil and weather extremes.',
          '<b>Species rankings for various environmental characteristics</b>',
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_droughty_soils.png" />`,
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_ease_of_establishment.png" />`,
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_overwintering.png" />`,
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_regrowth_potential.png" />`,
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_soil_ph.png" />`,
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/species_ranking_for_wet_soils.png" />`,
        ]
      },{
        title: 'Anti-Quality Components',
        content: [`Cool-season grass species with potential anti-quality issues include reed canarygrass and tall fescue [<a href="${process.env.PUBLIC_URL}/gis/GIS6_Reed_Canarygrass.pdf" target="_blank" rel="noreferrer">GIS-6</a>, <a href="${process.env.PUBLIC_URL}/gis/GIS7_Tall_Fescue.pdf" target="_blank" rel="noreferrer">GIS-7</a>, <a href="${process.env.PUBLIC_URL}/gis/GIS16_Manure_Application_to_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-16</a>]. Old cultivars of both species have very bad reputations, with good reason, particularly if being considered as lactating dairy cow forage. Wild-type reed canarygrass contains a high indole alkaloid content, resulting in poor palatability and poor animal performance. Tryptamine alkaloids can result in neurologic "staggers" in ruminants that can result in death. While this was a significant problem in the past, almost all, if not all, reed canarygrass seed for sale is now low-alkaloid. Old stands, however, may still be a high alkaloid type. Old tall fescue cultivars contain an endophytic fungus that makes the plants healthier and stronger, but makes the forage unpalatable for dairy cows due to production of alkaloids, primarily ergot alkaloids. New endophyte-free tall fescue cultivars do not have these palatability problems.`]
      }]
    },{
      title: 'Summary of Perennial Grass Species',
      content: [],
      subsections: [{
        title: 'Reed Canarygrass',
        content: [`Reed canarygrass is native to the northern hemisphere around the world, but is often considered “invasive” due to its aggressive growth, often resulting in a monoculture. Seed from Europe was introduced to North America long ago, and at this time it is not clear how much of the “wild” reed canarygrass in North America is native vs. of European origin. It appears that European reed canarygrass is more aggressive than native types. Plants from different origins are indistinguishable morphologically, but genetic studies are underway to develop methods of distinguishing native and introduced reed canarygrass. Seed shatters when ripe and quickly drops to the ground, making seed production difficult. Reed canarygrass can be easily propagated using sod pieces or green stem material and has been used for gulley control. [<a href="${process.env.PUBLIC_URL}/gis/GIS6_Reed_Canarygrass.pdf" target="_blank" rel="noreferrer">GIS-6</a>]`]
      },{
        title: 'Tall Fescue',
        content: [
          `Tall fescue is native to Europe. Many new tall fescue cultivars are available and adapted to much of the northern USA. Just as the endophyte stigma of tall fescue has started to fade, a new type of endophyte has been introduced. Because endophyte-free tall fescue cultivars suffer from reduced persistence compared to the older fescue types in the central United States and in some countries overseas, breeders developed a “novel-endophyte.” Several new cultivars contain this less toxic endophytic fungus that will improve plant health and persistence. These cultivars do not exhibit the strongly negative effects on palatability and performance that older endophyte-infected cultivars do. There have not been persistence problems with adapted endophyte-free tall fescue in the Northeast, however, so the addition of a novel-endophyte may be of limited value in this region. Several years of trials indicated that there was no difference in yield or persistence between four pairs of novel endophyte/endophyte-free cultivars at three locations in NY. [<a href="${process.env.PUBLIC_URL}/gis/GIS7_Tall_Fescue.pdf" target="_blank" rel="noreferrer">GIS-7</a>]`,
          'Tall fescue, which is relatively easy to establish, is well suited to either hay/silage production or pasture systems. Tall fescue has survived northern New York winters over the past two decades and does not appear to have persistence problems in New York or Pennsylvania. Tall fescue is related to meadow fescue as well as annual and perennial ryegrass, permitting crossing between these species (e.g. some festulolium cultivars). From a forage quality standpoint, tall fescue is similar in fiber concentration and digestibility to other grasses when compared at similar growth stages. Tall fescue is typically lower in crude protein than other grasses, except for timothy. Intensive management is required for high silage yields in the 50% NDF range. Tall fescue often ranks highest for yield among cool-season grass species in the Northeast and the Midwest. Tall fescue has yielded up to 20% higher than the most popular perennial grasses grown in New York.'
        ]
      },{
        title: 'Timothy',
        content: [`Timothy is native to northern Europe. It is the most popular grass grown in NY, with most of it grown in mixture with alfalfa. This combination became popular due to the ease of establishment of timothy, the ability to use home-grown timothy seed, and timothy’s noncompetitive nature. It is a hay-type grass, with few basal buds for regrowth following grazing. It is somewhat unique among cool-season grasses with low protein content in harvested forage, often two percentage units lower than most of the other species. This trait, combined with low regrowth potential, make timothy a poor choice for dairy farmers. [<a href="${process.env.PUBLIC_URL}/gis/GIS9_Timothy.pdf" target="_blank" rel="noreferrer">GIS-9</a>]`]
      },{
        title: 'Orchardgrass',
        content: [`Orchardgrass is native to Europe, but has been grown in North America for two centuries. The name resulted from the plant's shade tolerance in orchards, outside of North America it is known as “cocksfoot”. Orchardgrass is a bunchgrass that produces an open sod. The crop is suitable for grazing, hay or silage. Orchardgrass is compatible with alfalfa, but tends to be very competitive, regardless of the initial orchardgrass seeding rate in the mixture. Later maturing cultivars have been developed to make the grass more compatible with alfalfa. Orchardgrass tends to have more leaf disease problems than most other cool-season grasses in the Northeast, and occasionally suffers from winterkill in the Northeast. Orchardgrass cultivars are being developed with a low frequency of heading, to maintain quality for a longer period for grazing use. [<a href="${process.env.PUBLIC_URL}/gis/GIS8_Orchardgrass.pdf" target="_blank" rel="noreferrer">GIS-8</a>]`]
      },{
        title: 'Smooth Bromegrass',
        content: [`Smooth bromegrass is native to central Europe and Russia. It is a sod-forming perennial and an excellent seed producer, tolerant of droughty soils, but relatively intolerant of wet soils. Smooth bromegrass is rarely grown in a pure stand, and is not well suited for mixture with alfalfa, so relatively little smooth bromegrass is grown in NY. It is occasionally grown in pure stands for horse hay. Smooth bromegrass is susceptible to a wide variety of foliar diseases that reduce forage quality. [<a href="${process.env.PUBLIC_URL}/gis/GIS10_Smooth_Bromegrass.pdf" target="_blank" rel="noreferrer">GIS-10</a>]`]
      },{
        title: 'Ryegrass',
        content: ['Perennial ryegrass is of Eurasian and North African origin. It has rapid establishment, relatively high yield, high forage quality, and tolerates a wide range of grazing conditions. It is not well suited for mixture with alfalfa. Snow molds can affect plant persistence if top growth is not removed in the fall. It is not recommended in pure stands for the Northeast USA, due to persistence issues. Management practices and the particular environment conditions of a given site combine to have a significant to devastating effect on persistence. Annual or Italian ryegrass also has limited use in the Northeast region, it is generally used where a rapidly growing emergency crop is needed, or for erosion control in new seedings. Italian ryegrass is very high quality, but it may overwinter it situations where an annual forage was desired. [<a href="${process.env.PUBLIC_URL}/gis/GIS11_Perennial_Ryegrass.pdf" target="_blank" rel="noreferrer">GIS-11</a>]']
      }]
    }]
  },
  'Cultivar Selection': {
    chapter: {
      content: ['The number of perennial grass cultivars available has increased dramatically in the past decade. Tall fescue, for example, was reported to have over 135 forage-type cultivars available for certification in 2005. In the past the primary goal for cultivar selection was to exclude cultivars with obvious anti-quality components such as alkaloids and endophytes. From a yield standpoint, the most effective method of grass cultivar selection is to check out grass cultivar yield trials, if available in your environment or in similar environments. Orchardgrass and timothy cultivars are now available in a wide range of heading maturities, to make them more compatible to your specific use. There are now numerous tall fescue cultivars with “soft-leaf” type characteristics, making them more palatable for grazing. Breeding cool-season perennial grasses for improvements in forage quality has only recently begun.'],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_3_pic.jpg`,
        caption: 'A tall fescue trial in Freeville, NY, with 45 cultivar entries. The number of cultivars to choose from is steadily increasing.',
        alt: 'A tall fescue trial in Freeville, NY'
      }]
    },
    sections: [{
      title: 'Comparison of Cultivars',
      content: ['An effective method is needed to compare these cultivars for both yield and quality. Currently for grass cultivar trials, all entries in a trial are harvested on the same day, regardless of maturity, or an attempt is made to harvest entries at a similar maturity stage. It is difficult to make varietal comparisons using either of these methods. Same-day harvesting of cultivars is biased by plant maturity differences. Harvesting on different days to correct for plant maturity is biased by the differing environmental conditions preceding each harvest date. Normally forage NDF increases steadily in the spring, but it is possible for forage NDF to temporarily drop as much as 5 percentage units following a stretch of cold weather, due to an accumulation of sugars. Harvest on different days cannot take weather shifts into account. Adjusting yield and quality for maturity (e.g. heading date) is not satisfactory, as the relationship between heading date and both yield and quality for a set of cultivars is relatively poor.'],
      subsections: []
    },{
      title: 'New Method for Comparing Cultivars',
      content: [
        `There is a method to compare yield and digestibility of entries in the spring cut on the same day by adjusting yield and digestibility to a date with similar NDF level. This can be accomplished by determining the linear rate of change of yield and quality over time. While rates of change vary slightly among cultivars, an average rate of change over time for yield or quality adequately represents all cultivars in a trial. Ranking grass cultivars for milk/acre is virtually identical to ranking them for dry matter yield/acre. This system, however, places more emphasis on quality and results in significantly different varietal rankings, compared to unadjusted yield and digestibility. The single best quality comparison under this system is to compare fiber digestibilities adjusted to a common neutral detergent fiber (NDF). The best comparison to combine yield and quality into one term is to rank dry matter yields of cultivars that are adjusted to a common NDF. [<a href="${process.env.PUBLIC_URL}/gis/GIS24_Comparing_Grass_Cultivars_in_the_Spring.pdf" target="_blank" rel="noreferrer">GIS-24</a>]`,
        'Reference: Cherney, J.H., D. Parsons, and D.J.R. Cherney. 2011. A method for forage yield and quality assessment of tall fescue cultivars in the spring. Crop Sci. 51:2878-2885.'
      ],
      subsections: []
    }]
  },
  'Seeding and Establishment': {
    chapter: {
      content: [
        'If sowing alfalfa-grass, it should not be planted immediately after removal of an old alfalfa stand, or alfalfa autotoxicity may damage alfalfa seedlings. Fields with residual herbicides that could damage a forage seeding must be avoided. Preparation for seeding should begin with a representative soil sample [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet1.pdf" target="_blank" rel="noreferrer">FS-1</a>, <a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet5.pdf" target="_blank" rel="noreferrer">FS-5</a>] taken well before seeding, to allow for adjustment of soil pH if necessary [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet6.pdf" target="_blank" rel="noreferrer">FS-6</a>]. Perennial grasses have relatively small seeds that require a well-prepared, fine, and firm seedbed for successful germination and establishment.',
        `Rolling or cultipacking the soil before and after planting will result in better establishment, unless the soil is prone to crusting. Rolling will also minimize the negative effects of stones on the soil surface. No-till drills can be effectively used to establish grasses but require special attention to seed-soil contact and compaction problems. Whatever seeding method is used, sow seed ¼ to ½ inch deep, somewhat deeper on sandy soils. Shallow, poorly covered seed will desiccate. Good seed-to-soil contact will maximize chances of uniform emergence. Seeding rates are influenced by the seeding mixture, soil conditions and desired management [<a href="${process.env.PUBLIC_URL}/gis/GIS5_Grass_Seeding_and_Establishment.pdf" target="_blank" rel="noreferrer">GIS-5</a>].`
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_4_pic.jpg`,
        caption: 'A well-prepared, firm seedbed is required for good seed-soil contact, note the shallow footprint in the photo.',
        alt: 'Picture of soil'
      }]
    },
    sections: [{
      title: 'Spring Seeding',
      content: [
        'Spring seedings have more available moisture and produce higher yields in the seeding year. Early planting maximized the chances of vigorous regrowth and development before the onset of hot, dry weather. Seed forage grasses in the spring as early as the ground can be prepared for planting. Grass seedings after May 1 in southern NY or May 15 in northern NY will have an increased risk of failure. A firm seedbed and accurate control of seed placement are essential.',
        'Minimum or no-till establishment minimizes soil losses and has lower fuel and labor requirements, particularly if the labor includes stone removal. A large roller can be used post-seeding to reduce the impact of stones on subsequent harvesting operations. Companion crops can be sown with the grasses to help control weed pressure and provide some early summer forage. They may set back the establishment of the grasses, however, particularly for grasses with weak seedlings such as reed canarygrass. Red clover has been used as a companion crop with reed canarygrass, providing forage the first two years while the grass becomes established. Even with low clover seeding rates, this practice can set back establishment of reed canarygrass up to a year.'
      ],
      subsections: []
    },{
      title: 'Late Summer Seeding',
      content: [
        'Spring seedings are difficult in very wet springs, and in some cases can compete with corn planting for time and labor. Late-summer seedings generally have fewer weed problems, can follow another crop, and occur during a time of fewer time and labor constraints on the farm. Moisture and seeding depth control are the keys to successful late summer forage seeding. If the land is idle during the summer, the seedbed should be prepared in early or mid summer to allow for moisture conservation. Another moisture-conserving option is to eliminate weeds with a herbicide, followed by a no-till seeding.',
        'The advantage of being able to grow an extra crop prior to a late summer seeding can end up a severe disadvantage if that crop removes all available moisture from the soil profile. Likewise, a small grain or other companion crop is not recommended with a summer seeding. The most common cause of seeding failures due to planting technique is placing seed too deep. Reed canarygrass should be planted in late July because seedlings need 6 weeks of growth before a hard freeze. Other perennial grasses should be seeded by mid-August in northern NY or late August in southern NY. Delayed late summer seeding of grasses from mid-August to mid-September at three sites in Wisconsin generally resulted in a one ton/acre yield loss the following year.'
      ],
      subsections: []
    },{
      title: 'Research Studies',
      content: [],
      subsections: [{
        title: 'Reed Canarygrass Seeding Study',
        content: [
          'Reed canarygrass is a high yielding, high quality perennial cool season grass that can be the most difficult cool-season perennial grass to establish in the Northeast USA. Farmers often state that it takes up to a year longer for reed canarygrass to become fully established, compared to other cool-season grasses. Reed canarygrass works best with a prepared seedbed and is not recommended for seeding into an unprepared seedbed, especially frost seeding. The nationally recommended seeding rate for reed canarygrass is 5 to 9 lbs per acre, while current University recommendations in the Northeast range from 8 to 14 lbs of seed/acre. Some organizations are recommending higher seeding rates for reed canarygrass, partly to help solve a perceived establishment problem.',
          `Our objective was to determine if an increase in the recommended seeding rate of reed canarygrass will speed up stand establishment and increase yield over the life of the stand. Plots were seeded with Rival reed canarygrass in prepared seedbeds at 5, 10, 15, 20 and 25 lbs per acre in early August of 2001 and 2002 at three sites [<a href="${process.env.PUBLIC_URL}/gis/GIS12_Reed_Canarygrass_Seeding_Rate_Studies.pdf" target="_blank" rel="noreferrer">GIS-12</a>]. Three harvests/year were made for two years after seeding and stands were evaluated for tiller development in the spring of the first production year.`,
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">Under ideal conditions a 5 lb/acre seeding rate might be sufficient, but ideal conditions cannot be relied upon.</li><li class="inlineListItem">Seeding rates as low as 5 lb/acre produced as much yield in the second year after seeding as higher seeding rates, but not always in the year following seeding.</li><li class="inlineListItem">This data does not suggest a change in reed canarygrass seeding rates in the Northeast.</li><li class="inlineListItem">Poor seedbed conditions or improper placement of seed will probably have a greater negative impact on reed canarygrass than other grasses.</li></ul>'
        ]
      },{
        title: 'Orchardgrass-Alfalfa Seeding Study',
        content: [
          `Orchardgrass has two major issues when grown in combination with alfalfa in the Northeast. The grass matures too early and it tends to be too aggressive with alfalfa. Satin orchardgrass is promoted as a late maturing cultivar that does not crowd out alfalfa when seeded at 0.5 to 1.0 lbs/acre. We established seeding trials at two sites in NY at Ithaca (good soil) and Mt. Pleasant (marginal stony soil) in 2003 [<a href="${process.env.PUBLIC_URL}/gis/GIS14_Orchardgrass-Alfalfa_Establishment_Studies.pdf" target="_blank" rel="noreferrer">GIS-14</a>]. Spring growth in late May of 2004 was sampled and grass and alfalfa were separated.`,
          'Orchardgrass was a smaller component of the mixture at the marginal site. Satin was clearly less competitive with alfalfa than OKAY, except at the high rate of seeding on the good soil. The only seeding rate resulting in less than 50% grass in the mixed stand was the 0.5 lbs/acre of Satin on a marginal soil. On a good soil, OKAY was very competitive with alfalfa, regardless of the seeding rate. Although this study was not continued for an additional year, the percentage of orchardgrass in a stand with alfalfa will most likely increase as the stand ages, particularly on sites not ideal for alfalfa.',
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">Orchardgrass seeding rate is site-dependent.</li><li class="inlineListItem">A half pound/acre of orchardgrass seed may be sufficient with good soil conditions.</li><li class="inlineListItem">Satin orchardgrass was less competitive, particularly at low seeding rates.</li></ul>'
        ]
      },{
        title: 'Tall Fescue-Alfalfa Seeding Rate Studies',
        content: [
          `New, improved tall fescue cultivars have generated interest in using tall fescue in mixture with alfalfa for dairy forage. Such mixtures are not generally recommended in the Midwest due to the strong competition that alfalfa faces from tall fescue. Initial attempts at alfalfa-fescue mixtures by NYS farmers have generally appeared to be successful, although droughty establishment conditions have resulted in some mixed stands with little fescue. We attempted several studies with a range of fescue seeding rates, with constant alfalfa seeding rate [<a href="${process.env.PUBLIC_URL}/gis/GIS13_Alfalfa-Fescue_Establishment_Studies.pdf" target="_blank" rel="noreferrer">GIS-13</a>].`,
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">Results were variable, primarily impacted by moisture availability after seeding.</li><li class="inlineListItem">The fescue component of mixed stands ranged from almost none to over 60% of the mixture, and this range often was not well correlated with the fescue seeding rate.</li><li class="inlineListItem">Currently, a 4-6 lb/a fescue seeding rate with alfalfa is suggested.</li></ul>'
        ]
      }]
    }]
  },
  'Fertilization of Established Stands': {
    chapter: {
      content: [
        `Wise use of nitrogen applied to perennial grasses can reduce nutrient management problems. Perennial grass must have a sufficient source of nitrogen in order to generate acceptable yields, the source can be either inorganic or organic in form. Current recommendations are to apply no more than 225 lbs of actual nitrogen to perennial grass stands [<a href="${process.env.PUBLIC_URL}/gis/GIS15_Nitrogen_Fertilization_of_Perennial_Grass.pdf" target="_blank" rel="noreferrer">GIS-15</a>]. Although grass will respond to more N than 225 lbs, it does so at decreasing efficiency, potentially resulting in unacceptable losses of N into the environment.`,
        'Cool-season grasses perform optimally at a soil pH between 6 and 7; pH should be corrected prior to seeding grass. A factsheet is available that discusses nutrient management planning in general [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet33.pdf" target="_blank" rel="noreferrer">FS-33</a>]. Several factsheets are available that discuss nitrogen sources [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet44.pdf" target="_blank" rel="noreferrer">FS-44</a>, <a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet45.pdf" target="_blank" rel="noreferrer">FS-45</a>]. There are also factsheets dealing with N fertilizer management of teff [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet46.pdf" target="_blank" rel="noreferrer">FS-46</a>] and sorghum-sudangrass [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet26.pdf" target="_blank" rel="noreferrer">FS-26</a>].'
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_5_pic.jpg`,
        caption: 'Fertilize at spring green-up, before grass reaches this stage.',
        alt: 'Close up of grass'
      }]
    },
    sections: [{
      title: 'Timing of N Application to Grasses',
      content: [
        'University of Minnesota has suggested that there was no additional yield response resulting from split application of N fertilizer on reed canarygrass. One application of N fertilizer in the spring produce maximum yields, compared to split applications. This differs from past recommendations in most regions.',
        'We set up an experiment to investigate split N application on perennial grasses in 2000. Reed canarygrass, orchardgrass and tall fescue were established in replicated small plots at two locations in central New York State, Ithaca, and Mt. Pleasant, a high elevation site. In 2001-2003 the following treatments were applied: 1) 200 lbs/a actual N in the spring; 2) 100 lbs N in the spring and 100 lbs after 1st harvest; 3) 100 lbs N in the spring, 50 lbs N after 1st harvest and 50 lbs N after 2nd harvest; and 4) No nitrogen fertilizer applied.',
        '<b>Conclusions:</b>',
        '<ul class="inlineList"><li class="inlineListItem">Over 3 years and 3 species there were no differences in forage yield between 200 lbs of N split applied twice (100+100) or split applied three times (100+50+50).</li><li class="inlineListItem">One application of N in the spring was consistently lower yielding than split applications of N, and averaged 8% lower yield.</li><li class="inlineListItem">The response to split application of N was smallest with reed canarygrass.</li><li class="inlineListItem">Tall fescue produced the highest yields.</li><li class="inlineListItem">Fertilized grasses yielded 124% more forage than unfertilized grass.</li></ul>'
      ],
      subsections: []
    }]
  },
  'Manure Application': {
    chapter: {
      content: [`Intensively managed perennial grasses have the potential to absorb large quantities of N from manure [<a href="${process.env.PUBLIC_URL}/gis/GIS16_Manure_Application_to_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-16</a>]. Proper manure storage allows farms to conserve ammonia-N and greatly increase the amount of useable N applied, if manure can be incorporated. Most manure is currently surface applied to grass fields. If solid or semisolid manure is applied there are potential contamination issues to consider. No more than about 20 tons/acre of semi-solid manure should be applied at one time, unless the harvesting season is over. Higher rates risk contamination of harvested forage, which can result in poor fermentation of silage and could expose dairy cattle to serious pathogens.`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_6_pic.jpg`,
        caption: '20 ton/acre of semi-solid dairy manure uniformly applied to grass after harvest.',
        alt: 'Sparse grass'
      }]
    },
    sections: [{
      title: 'Manure is Variable',
      content: ['There is significant variation in manure composition, such that book values for nutrients in manure are not reliable for nutrient management planning. There is a factsheet available for estimating nitrogen credits from manure [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet4.pdf" target="_blank" rel="noreferrer">FS-4</a>], and a factsheet for manure sampling, analysis, and interpretation [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet38.pdf" target="_blank" rel="noreferrer">FS-38</a>]. Factsheets also are available for manure use during alfalfa-grass establishment [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet42.pdf" target="_blank" rel="noreferrer">FS-42</a>], and application of manure to established alfalfa [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet16.pdf" target="_blank" rel="noreferrer">FS-16</a>].'],
      subsections: []
    },{
      title: 'Split Application of Dairy Manure',
      content: [
        'We applied dairy manure to orchardgrass and tall fescue, on land that had not received any manure application for several decades in Willsboro, NY. Grass received 30 wet tons/acre of manure split-applied at spring greenup and after spring harvest. Grasses were harvested three times per season. Relative N recovery in orchardgrass forage from dairy manure applied with or without fertilizer in our study was higher from commercial fertilizer N nitrogen applications than from manure applications. This study agrees with the results found elsewhere that perennial grasses can utilize large quantities of applied N, and our results extend this conclusion to orchardgrass and tall fescue.',
        '<b>Conclusions:</b>',
        '<ul class="inlineList"><li class="inlineListItem">Dry matter yield of grass receiving 30 wet tons/acre of manure was similar to that of N-fertilized grass after two years of manure application.</li><li class="inlineListItem">After 2 yr of manure applications residual effects of manure on grass yield were large for at least three years following manure application.</li></ul>'
      ],
      subsections: []
    },{
      title: 'Timing of Manure Applications',
      content: [
        `We also evaluated the impact of semi-solid manure application rate and timing on yield and quality of orchardgrass. Manure was applied at spring greenup, and after 1st, 2nd and 3rd cuttings in various combinations of treatments, with up to 80 tons/acre applied. Manure applications during the growing season were limited to not more than 20 tons/acre of semi-solid manure per application to minimize the chance of manure contamination of harvested forage. Timing of manure applications did not affect forage yield, but timing needs to be further evaluated for overall environmental impact. Commercial nitrogen fertilizer application resulted in significantly greater forage CP concentration compared with manure treatments [<a href="${process.env.PUBLIC_URL}/gis/GIS16_Manure_Application_to_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-16</a>].`,
        '<b>Conclusions:</b>',
        '<ul class="inlineList"><li class="inlineListItem">Timing of manure applications did not affect forage yield.</li><li class="inlineListItem">Application rate and timing of application of manure had a very minor affect on forage nutritive value.</li><li class="inlineListItem">By the second year of the study the highest rate of manure application yielded more DM than recommended N fertilizer applications.</li></ul>'
      ],
      subsections: []
    }]
  },
  'Forage Quality': {
    chapter: {
      content: ['The spring forage harvest is the most crucial of the year, and sets the stage for good harvest management the rest of the season. High forage quality will maximize use of on-farm feeds and improve feeding efficiency. Adverse health effects of high grain rations and high grain prices make it important to minimize grain use by optimizing the quality of forage harvested.'],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_7_pic.jpg`,
        caption: 'Mixed alfalfa-grass is ideal forage for lactating cows, if harvested at the optimum stage.',
        alt: 'Mixed alfalfa-grass'
      }]
    },
    sections: [{
      title: 'Optimum Forage Quality',
      content: ['How do we determine optimum forage quality? Measures such as digestibility, fiber digestibility, Relative Forage Quality (RFQ), Milk per ton, and Milk per acre are possibilities. In perennial grasses, fiber digestibility is almost perfectly correlated with in vitro true digestibility, so they are equivalent in usefulness. This relationship can only be significantly impacted by cultivars with radically different forage quality, such as brown-midrib mutants. There currently are no perennial grass cultivars with that level of improved forage quality. Also, dry matter yield per acre is highly correlated with milk yield per acre. Again, it would take quality differences on the scale of a brown-midrib to impact these high correlations. None of the above parameters have an optimum value, however, the higher the better. Therefore, none of them provide useful harvest date targets.'],
      subsections: []
    },{
      title: 'Morphological Stage and Forage Quality',
      content: [`It is very desirable to be able to estimate forge quality of perennial grasses. A relatively simple morphological staging system for grasses could provide information on forage quality and optimum harvest date. There are several systems that attempt to descriptively evaluate morphological stages in perennial grasses [<a href="${process.env.PUBLIC_URL}/gis/GIS17_Morphological_Staging_of_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-17</a>]. Grass stands are a mixed population of tillers at different stages of maturity. The large time investment needed to quantify the average growth stage of a grass stand is probably not worth the effort.`],
      subsections: []
    },{
      title: 'Spring Harvest Target',
      content: [`We define a forage as a crop that can meet the effective fiber needs of a cow when fed as the primary forage source in the diet [<a href="${process.env.PUBLIC_URL}/gis/GIS18_Grass_Nutritive_Value.pdf" target="_blank" rel="noreferrer">GIS-18</a>]. We need to harvest forage grasses and legumes to optimize the fiber content (NDF) for the class of livestock being fed, therefore NDF is the most useful harvest date target. There is a relatively small range in optimal NDF for lactating dairy cows, making correct harvest management decisions relative to quality critical. A reliable method to estimate the fiber content of grass and alfalfa-grass mixtures would help producers in timing harvesting operations to optimize the quality of the harvested forage [<a href="${process.env.PUBLIC_URL}/gis/GIS22_Predicting_Spring_Fiber_Content_of_Forages.pdf" target="_blank" rel="noreferrer">GIS-22</a>]. Once the forage is harvested and stored, an accurate forage quality analysis is needed prior to ration balancing. Fiber content is primarily a function of maturity. This is also true for CP, although species differ in CP content.`],
      subsections: []
    },{
      title: 'Environmental Stress',
      content: [`Temporary flooding, extended drought, and early or late season freezing of grasses can all have a significant impact on forage yield and quality. Each environmental stressor has its own concerns and management issues, and issues are specific to the grass species involved [<a href="${process.env.PUBLIC_URL}/gis/GIS28_Environmental_Stress_and_Forage_Quality.pdf" target="_blank" rel="noreferrer">GIS-28</a>]. Environmental stress usually has a negative impact on forage quality. Flood-damaged crops have a higher risk of clostridial fermentation. Drought affects yield more than quality. Freezing damage to annual forage crops can encourage mold growth in corn for silage, and release toxic prussic acid in some sorghum species. As with antiquality components of forages, dilution of the forage source in the ration is one practical method of minimizing risk when feeding environmentally stressed forages.`],
      subsections: []
    }]
  },
  'Anti-Quality Components of Grasses': {
    chapter: {
      content: [`Perennial grasses can contain a variety of antiquality factors that negatively impact animal performance [<a href="${process.env.PUBLIC_URL}/gis/GIS19_Common_Grass-Livestock_Disorders.pdf" target="_blank" rel="noreferrer">GIS-19</a>]. Plant species, management, and environment all play a role in whether a particular antiquality factor will be present in a concentration that results animal health problems. Toxic compounds may be naturally occurring in the plant, or may be the result of infestation by a microorganism or insect.`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_8_pic.jpg`,
        caption: 'Old cultivars of tall fescue were infected with an endophytic fungus that caused a variety of health problems, and gave fescue a bad reputation.',
        alt: 'Tall fescue grass'
      }]
    },
    sections: [{
      title: 'Seasonal Disorders',
      content: ['Mineral uptake is seasonal, and is impacted both by management and environment. Grass tetany is a magnesium deficiency associated with cool-season grass grazing in the spring. Grass tetany is rarely a problem in New York State. Nitrate poisoning results from high rates of N fertilizer application to grasses, in association with cool temperatures, drought, or other plant stress situations. Nitrate levels in grass will peak about 2-3 weeks following application. A forage nitrate concentration of about 1000 ppm nitrate-N or less is safe to feed as the primary forage in a ration. Prussic acid poisoning can occur with most sorghum species with immature, wilted, frosted or stunted plants. Sorghum species with prussic acid should not be grazed or fed green-chop if less than 15-18 inches tall. High rates of fertilizer increase the problem. Sorghum silage and hay are generally safe from prussic acid poisoning.'],
      subsections: []
    },{
      title: 'Plant Toxins',
      content: ['Old cultivars of reed canarygrass, and all wild reed canarygrass, are high in alkaloid content. High alkaloid content can cause serious neurological disorders, while lower concentrations cause less serious problems of diarrhea and weight loss. Essentially all seed currently sold is low alkaloid types, but care should be taken if utilizing any wild reed canarygrass for forage purposes. Old cultivars of tall fescue were infected with an endophytic fungus that caused a variety of toxic syndromes leading to serious animal health problems. The fungus travels with the seed, but prolonged seed storage eliminates the fungus. Almost all tall fescue now sold is endophyte-free, or deliberately infected with a novel endophyte that confers plant stress tolerance without production of toxic ergot alkaloids.'],
      subsections: []
    }]
  },
  'Harvest Management': {
    chapter: {
      content: [`Significant advances have been made in recognizing the potential of well-managed grasses for silage, but on average the level of management is still not as high as with alfalfa [<a href="${process.env.PUBLIC_URL}/gis/GIS20_Grass_Harvest_Management.pdf" target="_blank" rel="noreferrer">GIS-20</a>]. Harvest management controls forage quality [<a href="${process.env.PUBLIC_URL}/gis/GIS18_Grass_Nutritive_Value.pdf" target="_blank" rel="noreferrer">GIS-18</a>] and high forage quality is essential if a lactating dairy quality forage is desired.`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_9_pic.jpg`,
        caption: 'Wide swathing experiment. Mower with narrow, conditioned swath (center) and one wide conditioned swath and one wide unconditioned swath.',
        alt: 'Tractor mowing a field'
      }]
    },
    sections: [{
      title: 'Harvest Recommendations',
      content: [],
      subsections: [{
        title: 'Grass Harvest Recommendations',
        content: ['50-55% NDF for hay or silage. Suggest 3 cuts/season. Two early cuts for lactating dairy feed, one late cut for dry cow feed. Best Harvest management aid: Harvest in mid-late May in boot stage before heading. As soon as a single head emerges from the boot, grass is ready for harvest. Harvest 2nd harvest 30-35 days later, as the quality decline in regrowth is as rapid as in spring growth. Take a third harvest in September, timing is not critical. The first two harvests should be of optimum fiber content with an acceptable CP content (approx. 15-18% CP). No matter when the fall harvest is made, this forage will be low in CP, relatively low in NDF and relatively low in digestible fiber. Fall-cut grass also tends to be lower in potassium than spring cut forage, making it a reasonable dry cow feed.']
      },{
        title: 'Alfalfa-Grass Harvest Recommendations',
        content: ['If 50% or more grass, use N management like it was 100% grass. If 30% or more grass, apply harvest management as if it were 100% grass. Grass-legume forage is better than either pure alfalfa or pure grass. We suggest three cuts/season for much of the Northeast. As with pure grass, the first two cuts are taken for lactating dairy feed, with a goal of 50-55% NDF. A third harvest in the fall for dry cow forage can be taken when convenient.']
      }]
    },{
      title: 'Grass NDF Assessment',
      content: [`It is particularly difficult to assess standing quality of pure grass without actually analyzing samples. Grass morphology changes are not obvious until heading [<a href="${process.env.PUBLIC_URL}/gis/GIS17_Morphological_Staging_of_Perennial_Grasses.pdf" target="_blank" rel="noreferrer">GIS-17</a>] and once grass has headed it is too late. Often a calendar date works as well as any morphological indicators for spring harvest. In our region this date varies from May 15 to May 30.`],
      subsections: []
    },{
      title: 'Alfalfa-Grass NDF Assessment',
      content: [
        `Timing of spring forage harvest is critical to obtain optimal quality for animal production. The NDF concentration is the principal forage quality variable of concern for forage that serves as the primary fiber source in the diet of dairy cattle. The target NDF at harvest is approximately 38% for pure alfalfa and 50% NDF for pure grass, and the acceptable range is relatively small. These goals assume a 10-15% decline in forage quality due to harvest, storage and feedout. There is a need for quick and accurate methods for determining NDF of grass-legume mixtures [<a href="${process.env.PUBLIC_URL}/gis/GIS22_Predicting_Spring_Fiber_Content_of_Forages.pdf" target="_blank" rel="noreferrer">GIS-22</a>]. A number of methods have been developed to estimate alfalfa NDF, including models based on weather, chronological age, and plant morphology. Maximum alfalfa height works as well as anything for describing both alfalfa and grass development in a mixed stand.`,
        'We have equations for estimating mixed stand NDF, based on alfalfa height, sampling date, and an estimate of the grass:legume ratio. Prediction equations for NDF of mixed alfalfa-grass stands were developed based on sampling and separating mixtures from fields across NYS in 2004 and 2005. It will be possible in the future to predict forage composition of a field using NIRS from orbiting satellites, but until then our equations are the next best thing.'
      ],
      subsections: []
    },{
      title: 'Wide Swathing',
      content: [`Forage conditioners were developed to speed the drying of forage for hay. When the shift was made from hay to silage, mowing into a narrow swath saved a trip over the field and still dried to silage moisture relatively quickly. It was discovered relatively recently that mowing grass into a wide swath allowed forage to dry fast enough to chop the same day it was mowed [<a href="${process.env.PUBLIC_URL}/gis/GIS21_Wide_Swathing_for_High_Quality_Forage.pdf" target="_blank" rel="noreferrer">GIS-21</a>]. Mowing and chopping of forage on the same day eliminates forage quality overnight losses and can eliminate the risk of rainfall losses of quality by mowing on good days. Wide swathing to 85% or more of the cut width does not require any conditioning to reach silage moisture for same-day chopping. Wide swathing may require some equipment changes to match up mowing, merging and chopping on the same scale, but the improvement in silage quality is well worth it.`],
      subsections: []
    },{
      title: 'Pure Grass Stubble Height',
      content: ['Recent studies with corn harvested for silage suggest that increasing the stubble height will improve the nutritive value of corn silage, but this directly impacts the grain to stover ratio in grain crops. It is obvious that fully headed perennial grass cannot be turned into high quality forage through increased stubble height. It is not clear, however, if grass slightly past the optimum harvest window can be salvaged for lactating dairy feed by using a higher stubble height.'],
      subsections: []
    },{
      title: 'Grass Stubble Height Research Study',
      content: [
        `We studied the feasibility of harvesting grass at a higher stubble height to improve forage quality [<a href="${process.env.PUBLIC_URL}/gis/GIS23_Grass_and_Alfalfa_Stubble_Height_Issues.pdf" target="_blank" rel="noreferrer">GIS-23</a>]. Orchardgrass, reed canarygrass, and tall fescue were fertilized with 0, 100 and 200 lb N fertilizer/acre at spring greenup and sampled in late May or early June for 3 years, just past optimum fiber content, with NDF in the mid to upper 50’s. Rate of change per inch of increased stubble height on a percentage basis was small for forage quality parameters, resulting in small changes in milk/ton (0.83 + 0.42%) and relative forage quality (1.7 + 0.84%) estimates per inch. The much greater change in yield compared to quality with increased stubble height resulted in a -5.4 + 1.3% change in milk/acre/inch.`,
        'Once first-cutting perennial grasses have past the harvest window for optimum forage quality, raising the cutter bar at harvest will not improve overall quality sufficiently to warrant this practice as a management tool. We sampled just past optimum quality, so cutting at a more mature stage would have even less impact on forage quality. The small increase in forage quality with increased stubble height does not offset the significant loss of DM yield, regardless of grass species or level of N fertilization.',
        '<b>Conclusions:</b>',
        '<ul class="inlineList"><li class="inlineListItem">Increased cutting high enough to increase forage CP by one percentage unit, or to reduce NDF by one percentage unit, reduces overall DM yield by 12% on average.</li><li class="inlineListItem">Application rate and timing of application of manure had a very minor affect on forage nutritive value.</li><li class="inlineListItem">By the second year of the study the highest rate of manure application yielded more DM than recommended N fertilizer applications.</li><li class="inlineListItem">There is no reason to cut perennial cool-season grasses any higher than the minimum stubble height needed to avoid hitting stones or soil in rough fields.</li></ul>'
      ],
      subsections: []
    },{
      title: 'Alfalfa Stubble Height',
      content: [
        `As a preliminary step in evaluating alfalfa-grass stubble height effects, we developed regression equations to estimate the impact of stubble height on preharvest NDF concentration of alfalfa [<a href="${process.env.PUBLIC_URL}/gis/GIS23_Grass_and_Alfalfa_Stubble_Height_Issues.pdf" target="_blank" rel="noreferrer">GIS-23</a>]. In New York, where the stubble height of alfalfa varies due to terrain, accuracy of predictive equations may be reduced. We developed and evaluated alfalfa NDF equations that incorporate intended stubble height. Stands of first-cut alfalfa were sampled in producers’ fields in New York in 2007, and cut into 2-inch segments. Predictive NDF equations were developed to evaluate the effect of changing stubble height on yield and quality. These equations can be used by New York producers to obtain a more accurate estimate of preharvest NDF concentration. Alfalfa increases about 0.70 percentage units NDF/day, while grass increases about 0.85 to 1.0 percentage units/day.`,
        '<b>Conclusions:</b>',
        '<ul class="inlineList"><li class="inlineListItem">For every inch of stubble height left in the field, alfalfa NDF will decrease about one percentage unit.</li><li class="inlineListItem">Increasing the mowing height by 2 inches would move the optimum harvest date forward about 2.5 days.</li><li class="inlineListItem">In a 50:50 alfalfa-grass stand, leaving one additional inch of stubble would result in forage quality similar to harvesting one day earlier, with substantial yield loss.</li></ul>'
      ],
      subsections: []
    }]
  },
  'Grass for Nonlactating Cows': {
    chapter: {
      content: [
        `Potassium (K) concentration in perennial grass forages is considerably more important than nitrogen (N) or crude protein (CP) content, when used as dry cow forage. Relatively low K concentration in non-lactating dairy cow diets is critical to animal health. Species selection, fertilization, harvest management, and time of season can all impact the K concentration of grass forage [<a href="${process.env.PUBLIC_URL}/gis/GIS25_Grass_for_Dry_Cows.pdf" target="_blank" rel="noreferrer">GIS-25</a>]. Perennial grasses are luxury consumers of K, resulting in high K forages grown on fields with excess soil K due to repeated animal manure applications. The goal of a K management program for perennial cool-season grasses for non-lactating dairy cow forage is to provide sufficient K for plant functions without accumulating excess plant K.`,
        'It is possible to achieve sufficiently low forage K concentrations for non-lactating dairy cow forage in cool-season grasses and maintain stand persistence. We conducted perennial grass experiments at several sites in New York state investigating proper grass species and cultivar selection, cutting management, as well as N and manure management necessary to produce an adequate supply of low K grass for dry cows.'
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_10_pic.jpg`,
        caption: 'Mature grass on soil low K availability will likely produce forage with low K content.',
        alt: 'Tall grass in a field'
      }]
    },
    sections: [{
      title: 'Potassium in Grasses',
      content: ['Most perennial grasses harvested twice per season should maintain high yields with approximately 1.5% K in spring growth forage (harvested at flowering or later) and 1.2% K in regrowth forage (harvested in the fall). Visible K deficiency symptoms in grass will not appear until forage K content drops to around 0.8% K or lower. Deficiency symptoms are difficult to identify and attribute to K, because symptoms are similar to those due to drought or normal senescence. Deficiency of K is seen as a yellowing and browning of dying leaf tips and margins. Potassium fertilization does not have a large effect on DM yield of cool-season perennial grasses under a low intensity harvest management regime.'],
      subsections: []
    },{
      title: 'Species/Cultivar Selection for Low Potassium',
      content: [
        'For New York State, our on-line program is available to provide site-specific forage species recommendations based on soil type, specifically dealing with forage for dry dairy cows (http://www.forages.org). The two primary factors to consider when selecting perennial forages species and cultivars for dry cow forage are 1) persistence and 2) K content.',
        'Potassium content of perennial grasses has been reported from as low as 0.1% K up to as high as 7.0% K. Warm season grasses tend to be lower in K content than cool season grasses. Although species rankings are not completely consistent, orchardgrass tends to be significantly higher in K content at a given maturity stage than other grass species. Both orchardgrass and perennial ryegrass tend to be mineral accumulators, with high levels of K and other minerals, if there is an adequate soil supply of these minerals. Timothy on the other hand, tends to have less luxury consumption of minerals, when surplus minerals are available in the soil. Therefore, with moderate to high available soil K, orchardgrass may accumulate up to 1 percentage unit or more K than timothy. Under conditions of stress (e.g. drought) or low available soil K, the difference in K content of forage across species will be minimized.',
        'There may be some differences in K content among cultivars within a grass species, but significant differences among cultivars have yet to be characterized. Breeding for low K content has rarely been attempted in grasses. Selection may be very difficult, because there is a significant effect of environment on K uptake in grasses, compared to uptake of other minerals, such as magnesium (Mg) and calcium (Ca). Breeding for high magnesium in tall fescue, however, has been attempted. High Mg may alleviate some of the problems caused by high forage K.'
      ],
      subsections: []
    },{
      title: 'Site Selection',
      content: [
        'Managing K concentration in perennial grass forage is very site-specific, owing to the tremendous variation in available K among soil types. Most soils contain large quantities of K, but very little of it is available to plants. Physical, chemical and biological processes in the soil result in release of mineral K over time. Most soil K is found in fine-grained clays, and clay content is a good indicator of the potential K-supplying power of a soil. There is a wide range in K-supplying power of soils in most regions. Organic soils, as well as sandy soils, are typically low in available K.',
        'Soils with relatively high K-supplying power may never need any K fertilizer applied to maintain sufficient K available for grass growth and persistence. On the other hand, soils with low K-supplying power will require additional K fertilizer to avoid K deficiency in grass and reduce the risk of winter damage caused by low plant K. The amount of K released by the soil each year and made available for plant uptake varies with climatic conditions. Soil testing will identify fields with lowest soil K. These fields are prime candidates for dry cow grass management.'
      ],
      subsections: []
    },{
      title: 'Stage of Maturity and Time of Season',
      content: ['Grass forage declines in K content with increased plant age. Plant uptake of K is very high early in the season, but later on K uptake cannot keep up with plant dry matter accumulation and is diluted out. Potassium content of orchardgrass has exceeded 4.5% in late May in our studies. Grass harvested at flowering may have half the K concentration as grass earlier in the season. Concentration of K in grasses also tends to be significantly lower in regrowth, and also lower towards the end of the growing season. As a result, the grass forage with the highest K content is usually in the spring harvest. Although species vary in forage K concentration, cultivars within any given species are not significantly different for forage K concentration.'],
      subsections: []
    },{
      title: 'Nitrogen Fertilization',
      content: ['Nitrogen fertilization greatly increases uptake of K, if there is sufficient K available for uptake. We have almost doubled the K content of grass through high N fertilization rates, compared to no fertilizer N applied. Recommended N applications are 75-100 lbs actual N/acre at spring greenup and 75-100 lbs actual N/acre after spring harvest. Once excessive available soil K is removed however, the opposite trend in forage K content emerges. High N fertilization produces high yields, but very low soil K availability results in very low forage K concentrations. It may take only one season of moderate to high N fertilization to remove excess soil K. The forage produced the first season will likely be high in K content, but may be very low in K content in all succeeding years. After two years, soil test K dropped from “Medium” to “Very Low” (32 lb K/acre) in high N/no K stands on a soil type with average K-supplying power.'],
      subsections: []
    },{
      title: 'Potassium Fertilization',
      content: ['All forms of K fertilization must cease if low K grass forage is desired, until spring growth harvested near flowering drops to under approximately 1.7% K. It then may be time to consider some K fertilization, to maintain sufficient plant K for overwintering. The amount of K applied will depend on the K content of the spring harvested forage and the native K supplying power of the soil. From 50 to 100 pounds of potash fertilizer equivalent may be needed. Never apply K prior to spring growth, as there should be adequate soil K released during the winter and early spring for spring grass growth. Monitoring of forage K levels will allow the production of low K forage without risking damage to the stand. If low K fields are not available it may be economically feasible to fertilize fields with chloride at about 100 lbs Cl/acre, lowering the cation-anion ratio.'],
      subsections: []
    },{
      title: 'Manure Applications',
      content: ['It will be difficult to impossible to produce low K grass forage on stands receiving significant manure applications. Many dairy farms are in a state of potassium surplus due to years of spreading manure, and use perennial grass both as a manure sink and as dry cow forage. This results in potentially severe problems in properly managing potassium content of dry cow rations. If some K fertilizer is required due to low soil K, this could come from a manure application after spring harvest.'],
      subsections: []
    },{
      title: 'Research Studies',
      content: [],
      subsections: [{
        title: 'Grass Species and Two-Cut Management',
        content: [
          'Tall Fescue (Stargrazer), reed canarygrass (Palaton), Timothy (Tiller), smooth bromegrass (York), and orchardgrass (Artic) were sown at Ithaca in 1996 and Mt. Pleasant in 1997 to compare species under 3 rates of K fertilization with 100 lbs actual N fertilizer applied prior to each of two cuts per season. Average yields for 1998-1999 for Mt. Pleasant and 1997-2001 for Ithaca are shown in the Table. The higher elevation Mt. Pleasant site averaged 23% lower yields than Ithaca. At the Ithaca site with the deeper, soil resource, 65% of the total yield occurred in the spring, compared to 75% for Mt. Pleasant. Split application of K fertilizer after spring and fall harvests did increase DM yield and K uptake, compared to the 0 K treatment. Potassium deficiency symptoms were not observed on any of the grass species, although K concentration in fall harvested forage dropped below 0.8% in timothy. Forage quality also was not greatly affected by K fertilization, although it did affect forage K concentration and concentrations of some other elements.',
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">Potassium fertilization did not have a large effect on DM yield of cool-season perennial grasses under a low intensity harvest management regime.</li><li class="inlineListItem">Timothy forage tended to be low in elemental concentrations in general, with lowest forage K concentrations among the five grass species.</li><li class="inlineListItem">It was possible to achieve sufficiently low forage K concentrations for non-lactating dairy cow forage in all five cool-season grasses and maintain stand persistence.</li></ul>',
          `<img style="width: 80%; margin-left: 15%; max-width: 500px;border: 1px solid rgb(180,180,180);" src="${process.env.PUBLIC_URL}/grassman/table_for_chapt_10.png" />`
        ]
      },{
        title: 'Reed Canarygrass Yield Under Two-Cut Management',
        content: [
          'Our objective was to evaluate yield and stand persistence of reed canarygrass under two-harvest management and low availability of soil K. Three N and three K fertilizer treatments were applied to reed canarygrass for five years at two sites in central New York State. Reed canarygrass persisted under all treatments throughout the experiment, although K deficiency symptoms appeared in the high N, low K fertilizer combination. Stands receiving 200 lbs actual N/acre/season had reduced yields in 1997, due to very low soil K. Forage K levels for the high N treatment were under 1% K. Nitrogen fertilization decreased forage K concentration, but significantly increased total K uptake. Potassium fertilization had essentially no effect on forage K concentration, total DM yield, or total K uptake in the absence of N fertilizer. Potassium fertilization with constant N fertilization resulted in increased forage K concentration, yield of DM and annual K uptake.',
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">At the high N fertilizer rate, DM yield increased linearly with increased K fertilizer rate, while K fertilizer did not influence yield in the absence of N fertilizer.</li><li class="inlineListItem">Nitrogen-fertilized reed canarygrass under a two-harvest management with no K fertilization produced adequate forage yield.</li><li class="inlineListItem">The high N, low K, fertilizer combination did produce K deficiency symptoms.</li><li class="inlineListItem">Although reed canarygrass stand persistence in this study did not appear to be affected by prolonged K deficiency, it is prudent to maintain harvested grass forage K concentration above 12 g kg-1 DM to minimize risk to stand persistence.</li></ul>'
        ]
      },{
        title: 'Reed Canarygrass Forage Quality Under Two-Cut Management',
        content: [
          'Our objective was to evaluate nutritive value of reed canarygrass under two-harvest management and low availability of soil K. Reed canarygrass was established in 1992 and again in 1993 at two Ithaca sites. In 1995 and 1996, a two-cut management was initiated, with 3 rates of N fertilizer and 3 rates of K fertilizer. Crude protein averaged 16% for the spring harvest and 12% for the fall harvest at the high N fertilization rate in 1999. In vitro “true” digestibility (48-hour) declined with increased N fertilization, but remained above 70%. Neutral detergent fiber digestibility also declined with increased N fertilization, dropping to 62% in the spring and 50% in the fall at the high N fertilization rate. Neutral detergent fiber (NDF) averaged 65% in the spring and 58% in the fall at the highest N fertilization rate.',
          '<b>Conclusions:</b>',
          '<ul class="inlineList"><li class="inlineListItem">Nitrogen-fertilized reed canarygrass under a two-harvest management with no K fertilization produced forage with sufficiently high IVTD and NDF digestibility for non-lactating dairy cows.</li><li class="inlineListItem">Some CP supplementation in the diet may be required with mature grass forage.</li></ul>'
        ]
      }]
    },{
      title: 'Summary',
      content: [
        'Withholding K fertilization reduced the available soil K to low levels in less than two years. This minimized K uptake and resulted in very low forage K concentrations. With 100 lbs actual N fertilizer per acre applied at spring greenup and after spring harvest in June, forage K content averaged 1.1% in June forage and 0.6% in September forage. Although reed canarygrass in these studies did exhibit K deficiency symptoms, stands have survived and have been productive with adequate N fertilization. We recommend K fertilization in the form of 60-100 lbs K20 per acre as commercial fertilizer or manure if first cut forage < 1.7% K, or stand persistence will be at risk. Forage analysis for K should be done using wet chemistry not NIRS.',
        'Forage quality of grass in a two harvest system will not be of sufficient quality for lactating cows, but is sufficient for dry cows. The fiber content of grass harvested in mid to late June in New York State will be 65-70% NDF. Fiber content of regrowth grass harvested in mid to late September will be in the 50-60% NDF range. If 75-100 lbs actual N/acre are applied to each cutting, CP content of forage should be between 12-15% for both harvests. In vitro true digestibility will be around 60% for both harvests.'
      ],
      subsections: []
    },{
      title: 'Current Recommendations',
      content: [
        'The current prescription for grass forage for dry cows is as follows:',
        '<ul class="inlineList"><li class="inlineListItem">Select fields that have soils with low K supplying power, if you have a choice.</li><li class="inlineListItem">Use soil testing to identify fields with low K soil test for dry cow forage.</li><li class="inlineListItem">Use timothy, reed canarygrass, or smooth bromegrass, and avoid orchardgrass or perennial ryegrass (although if soil K is limiting, all species may have relatively low forage K).</li><li class="inlineListItem">Avoid all forms of K fertilization (No Manure).</li><li class="inlineListItem">Use moderate to heavy N fertilization (75-100 lbs/acre/harvest).</li><li class="inlineListItem">Harvest 2x/year, approximately mid-June and mid-September.</li><li class="inlineListItem">Use grass regrowth for cows close to calving, as it should be lowest in K content.</li><li class="inlineListItem">If K content of spring grass forage is below 1.7%, consider modest K fertilizer or manure additions after spring harvest (50-100 lbs potash or equivalent).</li><li class="inlineListItem">If soil K is too high, consider a spring application of chloride fertilizer up to 100 lbs Cl/acre to lower forage dietary cation-anion difference (DCAD).</li></ul>'
      ],
      subsections: []
    }]
  },
  'Annual Grasses for Emergency or Supplemental Use': {
    chapter: {
      content: [
        'When winter injury, wet spring planting conditions, or drought result in less forage than anticipated, or when additional livestock increase the demand for forage, emergency or supplemental forage is needed. There are a variety of crops that can be considered, depending on the time of year, and willingness of a producer to invest in the specific management required for each crop.',
        'Cold temperatures, lack of snow cover, and ice sheeting can damage perennial forages, particularly alfalfa. Spring or late summer perennial forage seedings fail about 10% of the time. A very wet spring season may delay or prevent planting of corn for silage, or may cause a planted stand to fail. Shortages of forage crops are most likely to occur in the summer, when hot, dry conditions reduce forage productivity.'
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_11_pic.jpg`,
        caption: 'Willsboro, NY experiment with corn and SxS at various summer planting dates.',
        alt: 'Far away picture of an experimental field in Willsboro, NY'
      }]
    },
    sections: [{
      title: 'Small Grains for Supplemental Forage',
      content: ['Small grains should be harvested at the flag leaf stage (mid-late boot stage) for lactating dairy cows [GIS-27]. Fiber digestibility declines quickly as the crop matures. Although overall forage quality moderates somewhat with grain formation, it is not enough to turn the crop back into a lactating dairy forage. Yield of these annual grasses, like any other grasses, is a function of available N. Small grains typically require 75 to 100 lbs N fertilizer to produce acceptable yields.'],
      subsections: []
    },{
      title: 'Winter Triticale',
      content: ['As with other small grains, winter triticale can yield 2 to 4 tons of dry matter per acre. It must be planted in late August or early September. The later it is planted, the lower the forage yield will be next spring. It can be drilled at a rate of about 100 lbs seed/acre. A small amount of N, along with soil test suggested rates of P and K, can be applied at planting. Manure can be used to supply fall N/P/K, but too much manure will result in excessive fall growth. When the crop starts growing in the spring, apply about 100 lbs N/acre. Manure can supply some of the needed N, but not all of it. The crop can be spring grazed or ensiled in bunkers or bales. Winter wheat or winter rye could be substituted under this same management scheme, but both will have lower forage quality.'],
      subsections: []
    },{
      title: 'Spring Oats, Barley, or Triticale',
      content: ['These crops can either be seeded in the spring or sown in August for fall pasture. Barley will be slightly higher in silage quality than other small grains, but all need to be harvested at a late boot stage. These are seeded at about 60-80 lbs/acre and require 75-100 lbs N fertilizer. If used as a spring companion crop with an alfalfa-grass seeding, both seeding rate and N fertilizer should be reduced to minimized the chances of lodging.'],
      subsections: []
    },{
      title: 'Small Grain-Pea Mixtures',
      content: ['Small grain-pea mixtures can be used as companion crops for establishing alfalfa, but may be too competitive for alfalfa-grass seedings. They can also be used as an emergency silage crop seeded solely for that purpose. If possible, shorter cultivars of small grains should be chosen for this mixture when used as companion crops, to reduce competition. Small grains are seeded at about 2 bushels/acre, and peas at about 4 pea seeds per square foot. Pea cultivars can differ greatly in pea size. If dry conditions are anticipated, seeding rates should be reduced if used as a companion crop with alfalfa. Peas will improve forage quality and palatability, and provide some N to the small grain crop, but will not increase overall yield.'],
      subsections: []
    },{
      title: 'Sorghums',
      content: [
        `Forage sorghums are tall to very tall growing warm-season annuals that are generally harvested once for silage or green-chop. Sudangrass will grow to 5-6 feet tall and is much finer stemmed than forage sorghum. Sorghum-sudangrass hybrids resemble sudangrass more than forage sorghum, but has larger stems and leaves and higher yields than sudangrass. Both sudangrass and sorghum-sudangrass will regrow after each harvest, the number of harvests will depend on growing conditions and the length of the growing season. If the sorghum used is known to accumulate prussic acid, then grazing or green chopping should be avoided with immature plants, or after a killing freeze [<a href="${process.env.PUBLIC_URL}/gis/GIS19_Common_Grass-Livestock_Disorders.pdf" target="_blank" rel="noreferrer">GIS-19</a>].`,
        'Forage sorghums are seeded at about 10-12 lbs/acre. Seeding rates for sudangrass and SxS hybrids are 10 to 20 lbs/acre, depending on moisture availability and seeding method. Seeding can be in rows with a corn planter, narrow rows with a grain drill, or broadcast and packed in with a roller. Sorghums should receive P and K according to soil test, and 40 to 80 lbs N at seeding. Some nitrogen (50 lbs/a) should be applied after harvest when expecting regrowth. Sudangrass and SxS should be harvested when they are between 36 and 48 inches tall, or have reached the boot stage. They will require wilting to achieve proper ensiling moisture. Millets are not the same species as sorghums, but are managed similarly. The do not contain any prussic acid, but are generally too slow growing under the relatively cool Northeast climate to consider here.'
      ],
      subsections: []
    },{
      title: 'Teff for Emergency Forage',
      content: [
        'Teff is a warm-season annual grass native to Ethiopia, where it is used as a grain crop for producing flour. In the Northeast, it is a very rapidly growing forage grass that can be grown as an emergency crop [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet24.pdf" target="_blank" rel="noreferrer">FS-24</a>, <a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet46.pdf" target="_blank" rel="noreferrer">FS-46</a>]. Teff requires warm soils and will not tolerate frost. Suggested seeding rate is 4-5 lbs/acre, with P and K fertilization similar to cool-season grasses. A firm seedbed is essential or the very small seed will be seeded too deep when seeded with a cultipacker-seeder. Teff is unusual in that it only requires 50 lbs N/acre, higher rates result in severe lodging. If soils are warm, teff can emerge in 3-4 days after sowing.',
        'Teff should be harvested at a pre-boot to early-boot stage, which will occur 50-55 days after planting. It can be baled, ensiled or grazed. Seasonal yields (2-3 cuts) will be between 3 and 5 tons dry matter/acre. Teff will continue regrowing until the first frost, although growth rate slows considerably in the fall. Forage quality is moderate, with 15-16% CP and fiber digestibility around 70%.'
      ],
      subsections: []
    },{
      title: 'BMR Sorghum-Sudangrass (SxS) vs. Late Planted Corn',
      content: [`Brown-midrib SxS has been investigated for supplemental forage in NYS [<a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet14.pdf" target="_blank" rel="noreferrer">FS-14</a>, <a href="http://nmsp.cals.cornell.edu/publications/factsheets/factsheet26.pdf" target="_blank" rel="noreferrer">FS-26</a>]. It also has been compared with late planted corn for supplemental forage in NY [<a href="${process.env.PUBLIC_URL}/gis/GIS26_Corn_vs_SxS.pdf" target="_blank" rel="noreferrer">GIS-26</a>]. Full season and short season corn, BMR corn, and BMR SxS were planted every 10 days at three sites in northern NY starting June 1. BMR SxS was harvested each time it reached 36-40 inches in height. Under relatively ideal growing conditions with adequate moisture all summer, the yields produced were well related to planting date. Corn silage yield declined linearly with planting date, losing 0.35 tons/acre/day of delayed planting beyond June 1. Full season corn produced higher yields than SxS, even when planted in mid-July. Dryer summer weather would likely have favored SxS more than corn.`],
      subsections: []
    }]
  },
  'Grass Storage': {
    chapter: {
      content: [`Grass can be stored either as dry hay or as silage, but most grass stored as forage for lactating dairy cows is silage [<a href="${process.env.PUBLIC_URL}/gis/GIS29_Grass_Silage.pdf" target="_blank" rel="noreferrer">GIS-29</a>]. Although it is possible to make hay in the Northeast in the spring, the odds are against it. Grass is the most common crop ensiled in the world, followed by corn and alfalfa. Perennial grasses typically have higher fiber levels, which have the potential to limit intake and production. Management of grass for optimum silage fermentation and preservation will maximize the potential of this forage.`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_12a.jpg`,
        caption: 'Storage of grass harvested in spring is typically as silage. High quality grass between 50 and 60% moisture stored as baleage can make good quality silage if sealed and stored properly.',
        alt: 'Stacks of harvested grass, packed in white plastic'
      },{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_12b.jpg`,
        caption: 'Unacceptable grass storage.',
        alt: 'Rotting hay bales piled in the middle of a field'
      }]
    },
    sections: [{
      title: 'Silage Making',
      content: ['Silage is the fermented product of an agricultural crop, and a silo is any formal or informal storage structure for silage, everything from a bunker to a covered pile. If the structure limits oxygen, and the forage has sufficient water soluble carbohydrates, primarily lactic acid bacteria will ferment the sugars to lactic acid and other organic acids. Grass is not the perfect substrate for silage, compared to something like corn silage. Grass does tend to have lower buffering capacity than alfalfa and can also have higher concentrations of water soluble carbohydrates, which both translate to a quicker acidification during ensiling. Under good ensiling conditions pH will drop to 4 or below, at which time fermentation ceases and the silage is stable in storage.'],
      subsections: []
    },{
      title: 'Silo Type',
      content: ['Oxygen-limiting tower silos that unload from the bottom have high capital costs and make it very difficult to segregate different types of silage for different groups of animals. The silage produced is very high quality. Top unloading tower silos have lower capital costs, but higher dry matter losses than oxygen-limiting silos. Bunker silos have lower capital costs than tower silos, but silage quality is more operator-dependent. Correct packing and sealing of the top surface can result in high quality silage, poor packing and sealing can result in poor quality silage. Grass resists compression during packing more than other forages. Good packing won’t help if the grass crop is put up too wet.'],
      subsections: []
    },{
      title: 'Baleage',
      content: [
        'Attempting to make small scale silos (individual bales), that both ferment properly and are sufficiently sealed to prevent aerobic deterioration, is considerably more challenging than standard silage storage techniques. High quality grass forage is required to have sufficient sugar content for good fermentation. Ideal moisture content for baleage is between 50 and 60%. Lower moisture baleage will not ferment properly, although it may keep in storage for short periods, it is not stable. Exposure to any air will result in quick deterioration. Higher moisture baleage risks butyric acid production, resulting in sour silage that may become toxic to animals.',
        'Round balers with a variable chamber or with a hard core center are more likely to produce tight, dense bales. Balers with knives for pre-cutting will also result in dense bales. Dense bales will maintain their shape after wrapping, and will have less oxygen in them. Tedding a windrow and driving too fast while baling will are likely to result in less dense bales.',
        'Bales should be wrapped or bagged immediately after baling with at least 6 mils of plastic containing an ultraviolet radiation inhibitor. When wrapping, plastic layers should overlap 50% and the plastic should be stretched 50% for proper tension. Bales wrapped in the rain may not seal properly. Bales should be stored on a well-drained surface that is free of anything that might puncture the plastic or provide cover for rodents. Stored bales should be monitored weekly and holes repaired with tape designed for this purpose. Bales with holes should be fed as soon as possible.'
      ],
      subsections: []
    },{
      title: 'Small Scale Ensiling',
      content: ['Laboratory silos are now a practical method of comparing grass species and managements. Small scale silos are necessary when evaluating numerous experimental variables and their interactions involving different grass silages. The pH and VFA fermentation characteristics of vacuum-sealed polyethylene bags are stabile in as little as four days after ensiling. This system will be a valuable evaluation tool in the future as the number of grass cultivars proliferates.'],
      subsections: []
    }]
  },
  'Feeding Dairy Cows': {
    chapter: {
      content: [`There have been few studies in the USA with cows fed predominantly perennial grass-based TMR's. We have conducted a number of these grass feeding trials with medium to high producing cows [<a href="${process.env.PUBLIC_URL}/gis/GIS30_Feeding_Grass_to_Dairy_Cows.pdf" target="_blank" rel="noreferrer">GIS-30</a>]. Rather than use forage to concentrate ratios, which favors the higher quality forage, rations were balanced for maximum NDF in the diet that would not limit intake. This allowed most cows to attain their intake potential with optimal ruminal fills. It also maximized the use of homegrown feeds, which had a favorable impact on farm nutrient balance. The use of high grass forage diets is feasible only if the forage is relatively high quality [<a href="${process.env.PUBLIC_URL}/gis/GIS32_Precision_Feeding_and_Grasses.pdf" target="_blank" rel="noreferrer">GIS-32</a>].`],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_13a.jpg`,
        caption: 'Orchardgrass to be stored as silage for a dairy feeding trial.',
        alt: 'Mowed field'
      },{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_13b.jpg`,
        caption: '“Ag-Bag” unit for bagging grass silage used in feeding trials.',
        alt: 'Ag-Bag unit for bagging grass silage used in feeding trials.'
      }]
    },
    sections: [{
      title: 'Results from These Studies',
      content: [`<ul class="inlineList"><li class="inlineListItem">Variations in fiber digestibility of grasses can account for differences in intake and milk production of cows fed diets similar in chemical composition but varying in ingredient composition.</li><li class="inlineListItem">Grass-based TMRs produced similar quantities of milk as those fed alfalfa-based TMRs.</li><li class="inlineListItem">Dry matter intake increased as the portion of concentrate in diets increased, resulting in higher milk production in high tall fescue diets compared to high alfalfa diets [<a href="${process.env.PUBLIC_URL}/gis/GIS31_Feeding_Alfalfa-Grass_to_Dairy_Cows.pdf" target="_blank" rel="noreferrer">GIS-31</a>].</li><li class="inlineListItem">Comparing starch vs. sugar supplementation of grass-based diets, the non-fibrous carbohydrate (NFC) source did not influence intake or milk production, but sucrose feeding lowered N utilization efficiency, when replacing a portion of the high moisture corn in the diet.</li><li class="inlineListItem">Tall fescue and orchardgrass TMRs performed as well as alfalfa, but grass will require more concentrate in the ration than alfalfa.</li><li class="inlineListItem">Grass silage can produce as much milk per cow as alfalfa silage when rations are balanced. We observed no palatability problems with endophyte-free tall fescue.</li></ul>`],
      subsections: []
    },{
      title: 'Explanation of Feeding Trial Results',
      content: [
        'Milk production per cow is a major factor in determining dairy farm sustainability/profitability. The inclusion of non-fibrous carbohydrates (NFC) in the range of 35 to 42% of dietary dry matter is seen as a popular way to increase energy density and thus milk production. Balance of carbohydrates in the diet impacts milk production because it affects amount and ratios of ruminal volatile fatty acids produced, which in turn alters metabolism and partitioning of nutrients. A very compatible feedstock for grass diets is corn silage, which also increases energy density [<a href="${process.env.PUBLIC_URL}/gis/GIS33_Feeding_BMR_Corn_Silage.pdf" target="_blank" rel="noreferrer">GIS-33</a>].',
        'In the above studies, the higher the fiber in the forage, the more concentrate in the diet. This resulted in generally higher intakes and higher milk production. Higher concentrate feeding results in a shift toward propionic acid production by ruminal microbes. Propionic acid is used by the mammary gland to produce lactose, responsible for milk volume, which accounts for milk production of cows fed grass being as high as those fed a lower fiber alfalfa. Differences in DM intake and subsequent milk production can also be attributed in part to differences in fiber digestibility and indigestible residue, resulting from lignin differences, as well as to differences in NSC.'
      ],
      subsections: []
    }]
  },
  'Feeding Grass to Nonlactating Dairy Cows': {
    chapter: {
      content: ['Hypocalcemia results from a deficiency in plasma Ca at the onset of lactation in dairy cows, and is the main cause of several severe metabolic disorders. Milk fever is a metabolic disorder due to high Ca demand at the start of lactation. Animal nutritionists tend to be concerned with the dietary cation-anion difference (DCAD, expressed as milliequivalents per 100 grams dietary DM) in the total ration of a dairy cow. DCAD = [(K + Na) - (Cl + S)], although more complex forms of this relationship are sometimes used. During the transition period 3 to 4 weeks prior to calving, it is desirable to have a moderately anionic diet, to avoid milk fever and hypocalcemia. The effect can be minimized by reducing the level of cations (K and Na) and/or increasing the level of anions (Cl and S) in the diet. Anionic salts can be fed to lower DCAD, but a more effective strategy is to reduce K content of the forage for dry cows. Immediately after calving, however, a cationic diet is essential.'],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_14_pic.jpg`,
        caption: 'Timothy will generally be lowest in K content among cool-season grasses.',
        alt: 'Field of Timothy grass'
      }]
    },
    sections: [{
      title: 'Focus on Low K Forage',
      content: [
        // eslint-disable-next-line quotes
        "Although sodium (Na) content of grasses varies by species, the values are low, compared to K. Because grass has potential for very high K concentrations, K receives the most attention. It is desirable to minimize K content of grasses (K < 2%) to produce an anionic diet for dry cows. Consideration may also be given to increasing the energy content of the ration during the late transition phase, using corn silage or gain. The increased energy from these sources may help improve the cow's physical condition as well as dilute the higher K levels of other forages in the diet. Rations for dry cows based predominately on corn silage, however, are discouraged.",
        'If perennial grass is managed specifically for production of dry cow forage, relatively high yields of low K forage are possible. A tactical solution is to manage 10 to 15% of your forage production specifically for dry cow forage. Several feeding problems associated with lactating cows can be minimized if low K forage is available for dry cows.'
      ],
      subsections: []
    }]
  },
  'Economics of Grass': {
    chapter: {
      content: [
        `With no control over the price of milk, large scale dairy farmers can either control their costs more closely, or expand their operations, to increase net income. While expansion is feasible if there is access to additional capital and labor, it will only work if coupled with the management skills necessary to lower their unit cost of milk. Smaller scale dairy farmers not interested in expansion can either control costs more closely or shift to grazing. Grazing is feasible only if the operator is comfortable with increasing the level of management. Grazing is not a solution for operations that currently have management skill issues. Precision management of forages and feeding can economically benefit all dairy farmers [<a href="${process.env.PUBLIC_URL}/gis/GIS32_Precision_Feeding_and_Grasses.pdf" target="_blank" rel="noreferrer">GIS-32</a>].`,
        'Farm management decisions must be based on a good understanding of the costs and returns involved in milk production. The macroeconomic question of whether or not grass forage production is suitable for the Northeast is easily resolved. The Northeast USA is exceptionally well suited to the production of perennial grasses. It is the site-specific microeconomic questions and the decision-making processes involved that lead to profitability. Decisions involving issues like what crops to grow, level of fertilization, harvest management, off-farm feed purchases, and ration formulation will determine the financial success of the farm enterprise.'
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_15_pic.jpg`,
        caption: 'Grass cultivar trial at Chazy, NY. The extra seed cost for the latest cultivars has essentially no impact on the overall economic success of grass forage.',
        alt: 'Grass cultivar trial field in Chazy, NY'
      }]
    },
    sections: [{
      title: 'Enterprise Budgets',
      content: ['Dairy enterprise budgets are one of the most useful tools to evaluate the financial performance of farms, particularly farms with diversified operations. Fixed expenses do not change over the short term and are not influenced by productivity, such as insurance, depreciation, interest and taxes. Variable or operating expenses are tied to production, such as seed, fertilizer, and fuel expenses. By itemizing all fixed and variable expenses, along with gross receipts, it is possible to evaluate returns on a per acre basis or on a per cow basis.'],
      subsections: []
    },{
      title: 'Economic Evaluation of Feeding Trials',
      content: [`We used fixed and variable costs in association with grass feeding trials, to evaluate topics such as seed costs and corn prices [<a href="${process.env.PUBLIC_URL}/gis/GIS34_Economics_of_Feeding_Grass.pdf" target="_blank" rel="noreferrer">GIS-34</a>]. Seed cost is not a concern when selecting perennial forage species and cultivars, and is generally not a concern for annual forage species, as long as reasonable yields are anticipated. As might be expected, the price of corn becomes increasingly important as the amount of corn fed increases. As the price of concentrate feeds increases, the comparative advantage of grass mixed with alfalfa increases.`],
      subsections: []
    },{
      title: 'Possible Response to High Grain Prices',
      content: ['Grass-based dairying, with supplemental concentrates to maintain high milk production, will result in healthy animals, and can result in higher profitability if the grass forage is of high quality and supplemental grains are reasonably priced. As the price of grains continues to rise, however, at some point it may prove economical to harvest grass at a more immature growth stage, generating a higher quality forage that will result in less grain required for balancing rations. Factors that need to be considered are the price of feed grains, the total reduction in grain use through use of higher quality grass, the yield loss associated with harvest of grass at a more immature stage, and whether milk yield is impacted by such a change in diet. Studies are currently underway to investigate this issue.'],
      subsections: []
    }]
  },
  'Summary': {
    chapter: {
      content: [
        `Improved management for high quality, advances in equipment, and innovations related to bale silage and other storage options have made grass silage an attractive option for dairy farmers. Grass that cannot be harvested in a timely fashion may be utilized as biomass or bedding [<a href="${process.env.PUBLIC_URL}/gis/GIS35_Grass_for_Forage,_Biomass,_or_Bedding.pdf" target="_blank" rel="noreferrer">GIS-35</a>]. Grasses also have significant nutrient management benefits, particularly regarding manure management [<a href="${process.env.PUBLIC_URL}/gis/GIS36_Grass_and_the_Environment.pdf" target="_blank" rel="noreferrer">GIS-36</a>]. While species and cultivar selection as well as fertilization issues are important, harvest management will determine the success or failure of grass silage as high producing dairy cow forage.`,
        'Grass species and cultivar evaluation should be focused on maximum yield at optimum silage quality. A harvest date target based on optimum forage NDF for the class of livestock being fed is the goal. A three-cut management is suggested for much of the Northeast, with the first two cuts taken at optimum NDF, followed by a fall cut for dry cow forage. With appropriate fertilization and a two-cut harvest management, any grass stand can be specifically managed to produce low potassium forage for non-lactating cows.',
        'New tall fescue cultivars should be seriously considered for dairy systems in the northern USA, particularly those that combine grazing with silage options. The benefit of novel endophyte cultivars for the Northeast, however, has yet to be shown. Much of the alfalfa sown in the Northeast is sown in mixture with perennial grasses, such mixtures should lead to a better balance of ration ingredients to maximize total intake. A proper balance of grass and non-fibrous carbohydrates in the diet should increase intake and maximize milk production. In summary, high quality perennial grasses can fit well in the rations of high producing dairy cows.'
      ],
      img: [{
        url: `${process.env.PUBLIC_URL}/grassman/chapt_16_pic.jpg`,
        caption: 'Perennial grass is well suited to the Northeast climate and soils. Timely harvest management will determine it success or failure as high producing dairy cow forage.',
        alt: 'Field of grass'
      }]
    },
    sections: []
  },
  'References': {
    chapter: {
      content: [
        'Binder, E.M., D.J. Blodgett, J.F. Currin, D.L. Caudell, J.H. Cherney, and T. LeRoith. 2010. <i>Phalaris arundinacea</i> (Reed canarygrass) grass staggers in beef cattle. J. Vet. Diagn. Invest. 22 (5):802-805.',
        'Casler, M.D., R. E. Barker, J. H. Cherney, and Y. A. Papadopolous. 2004. Stability of nonflowering orchardgrass. Crop Sci. 44:1601-1607.',
        'Casler, M.D., J.H. Cherney, and E.C. Brummer. 2009. Biomass yield of naturalized populations and cultivars of reed canarygrass. Bioenerg. Res. 2:165-173.',
        'Casler, M.D., R.C. Johnson, R.E. Barker, M.M. Jenderek, Y.A. Papadopoulos, and J.H. Cherney. 2010. Feasibility of Seed Production from Non-flowering Orchardgrass. Crop Sci. 50:35-42.',
        'Cherney, D.J.R., M.A. Alessi, and J.H. Cherney. 2006. Influence of grass species and sample preparation on ensiling characteristics. Crop Sci. 46:256-263.',
        'Cherney, D.J.R., and J.H. Cherney. 2005. Forage yield and quality of temperate perennial grasses as influenced by stubble height. Online. Forage and Grazinglands doi:10.1094/FG-2005-0215-01-RS.',
        'Cherney, D.J.R. and J.H. Cherney. 2006. Split application of nitrogen on temperate perennial grasses in the Northeast USA. Online. Forage and Grazinglands doi: 10.1094/FG-2006-1211-01-RS.',
        'Cherney, D.J.R., J.H. Cherney and L.E. Chase. 2002. Influence of lucerne/fescue silage mixtures on milk production of early-lactation Holstein cows. J. Anim. Feed Sci. 11:555-564.',
        'Cherney, D.J.R., J.H. Cherney and L.E. Chase. 2002. Performance of lactating Holstein cows as influenced by forage species, maturity, and level of inclusion. Prof. Anim. Scientist 18:316-323.',
        'Cherney, D.J.R., J.H. Cherney and L.E. Chase. 2003. Influence of non-fibrous carbohydrates on lactation performance of cows fed fescue silage. J. Dairy Sci.86:3983-3991.',
        'Cherney, D.J.R., J.H. Cherney and L.E. Chase. 2004. Lactation performance of Holstein cows fed fescue, orchardgrass or alfalfa silage. J. Dairy Sci. 87:2268-2276.',
        'Cherney, D.J.R., J.H. Cherney and E.A. Mikhailova. 2002. Nitrogen utilization by orchardgrass and tall fescue from dairy manure or commercial fertilizer nitrogen. Agron. J. 94:405-412.',
        'Cherney, J.H. 1995. Spring alfalfa harvest in relation to growing degree days. p. 29-36. In Proc. Natl. Alfalfa Symp., 25th, Syracuse, NY. 27-28 Feb. 1995. Certified Alfalfa Seed Council, Woodland, CA.',
        'Cherney, J.H. and D.J.R. Cherney. 2003. Chapt. 4. Assessing Silage Quality. pp. 141-198. In (D.R. Buxton, et al., ed.) Silage Science and Technology. ASA Monograph Series No. 42, ASA, CSSA, SSSA, Madison, WI.',
        'Cherney, J.H., and D.J.R. Cherney. 2005. Agronomic response of cool-season grasses to low intensity harvest management and low potassium fertility. Agon. J. 97:1216-1221.',
        'Cherney, J.H., D.J.R. Cherney, and T.W. Bruulesma. 1998. Chapt. 6. Potassium Management. In (J.H Cherney and D.J.R. Cherney, ed.) Grass for Dairy Cattle. CAB Intl.',
        'Cherney, J.H., D.J.R. Cherney, and M.D. Casler. 2003. Low intensity harvest management of reed canarygrass. Agron. J. 95:627-634.',
        'Cherney, J.H., D.J.R. Cherney, and D. Parsons. 2006. Grass silage management issues. p. 37-49. In Proc. from Silage for Dairy Farms: Growing, Harvesting, Storing, and Feeding, Harrisburg, PA. 23-25 Jan. 2006. NRAES-181. Natural Resource, Agriculture, and Engineering Service, Ithaca, NY.',
        'Cherney, J.H., and R.L. Kallenbach. 2007. Chapt. 18. Forage Systems in the Temperate Humid Zone. Pages 277-290. In (R.F. Barnes et al., ed.) Forages: Vol. 2. The Science of Grassland Agriculture. 6th ed., Blackwell Publishing.',
        'Cherney, J.H., Q.M. Ketterings, M.H. Davis, and D.J.R. Cherney. 2010. Split Application of Nitrogen vs. Dairy Manure on Temperate Perennial Grasses. Online. Forage and Grazinglands doi:10.1094/FG-2010-0215-01-RS.',
        'Cherney, J.H., Q.M. Ketterings, M.H. Davis, and D.J.R. Cherney. 2010. Timing of semi-solid dairy manure applications does not affect yield and quality of orchardgrass. Agron. J. 102:537-543.',
        'Cherney, J.H., D. Parsons, and D.J.R. Cherney. 2011. A method for forage yield and quality assessment of tall fescue cultivars in the spring. Crop Sci. 51:2878-2885.',
        'Fick, G.W., P.W. Wilkens, and J.H. Cherney. 1994. Modeling forage quality changes in the growing crop. p. 757-795. In G.C. Fahey, Jr., et al. (ed.) Forage quality, evaluation, and utilization. ASA, CSSA, and SSSA, Madison, WI.',
        'Hall, M.H. and J.H. Cherney. 2010. Increased teff seeding rates in the Central and North Atlantic Regions of the United States increases forage yield. Online. Forage and Grazinglands doi:10.1094/FG-2010-0802-01-BR.',
        'Hall, M.W., J.H. Cherney and C.A. Rotz. 2007. Chapter 8. Saving Forage as Hay or Silage. Pages 121-134. In (E. Rayburn, ed.) Forage Utilization for Pasture-Based Livestock Production. NRAES-173. Natural Resource, Agriculture, and Engineering Service. Ithaca, NY.',
        'Hunter, M., Q.M. Ketterings, J.H. Cherney, P. Barney, T. Kilcer, and G. Godwin. 2009. Nitrogen needs of teff managed as a forage crop in New York. Online. Forage and Grazinglands doi:10.1094/FG-2009-0612-01-RS.',
        'Jonker, J.S., D.J.R. Cherney, D.G. Fox, L.E. Chase, and J.H. Cherney. 2002. Orchardgrass versus alfalfa for lactating dairy cattle: production, digestibility, and N balance. J. Appl. Anim. Res. 21:81-92.',
        'Ketterings, Q.M., J.H. Cherney, G. Godwin, T.F. Kilcer, P. Barney, and S. Beer. 2007. Nitrogen management of brown midrib sorghum x sudangrass in the Northeastern USA. Agron J. 99:1345-1351.',
        'Ketterings, Q.M., E. Frenay, J.H. Cherney, K. Czymmek, S.D. Klausner, L.E. Chase, and Y. Schukken. 2007. Application of manure to established stands of alfalfa and alfalfa-grass. Online. Forage and Grazinglands doi:10.1094/FG-2007-0418-01-RV.',
        'Ketterings, Q.M., G. Godwin, T.F. Kilcer, P. Barney, M. Hunter, J.H. Cherney, and S. Beer. 2006. Nitrogen, phosphorus, potassium, magnesium and calcium removal by brown midrib sorghum sudangrass in the northeastern USA. J. of Agon. and Crop Sci.',
        'Kilcer, T.F., Q.M. Ketterings, J.H. Cherney, P. Cerosaletti, and P. Barney. 2005. Optimum stand height for forage brown midrib sorghum x sudangrass in northeastern USA. J. Agron. and Crop Sci. 191:35-40.',
        'McBride, M.B. and J. H. Cherney. 2004. Molybdenum, sulfur and other trace elements in farm soils and forages after sewage sludge application. Comm. Soil Sci. Plant Analy. 35(3-4): 517-535.',
        'Parsons, D., J.H. Cherney, and H.G. Gauch. 2006. Estimation of spring forage quality for alfalfa in New York State. Available <a href="http://www.plantmanagementnetwork.org/pub/fg/research/2006/alfalfa/" target="_blank" rel="noreferrer">here</a>. Forage Grazingl. doi:10.1094/FG-2006-0323-01-RS.',
        'Parsons, D., J.H. Cherney, and H.G. Gauch. 2006. Estimation of preharvest fiber content of mixed alfalfa-grass stands in New York. Agron. J. 98:1081-1089.',
        'Parsons, D., J.H. Cherney, and H.G. Gauch. 2006. Alfalfa fiber estimation in mixed stands and its relationship to plant morphology. Crop Sci. 46:2446-2452.',
        'Parsons, D., J.H. Cherney, and P.R. Peterson. 2009. Pre-harvest fiber concentration of alfalfa as influenced by stubble height. Agron. J. 101:769-774.',
        'Post, C.J., DeGloria, S.D., Cherney, J.H., and E.A. Mikhailova. 2007. Spectral measurements of alfalfa/grass fields related to forage properties and species composition. J. Plant Nutrition 30:1779-1789.',
        'Sulc, R.M., K.A. Albrecht, J.H. Cherney, M.H. Hall, S.C. Mueller, and S.B. Orloff. 1997. Field testing a rapid method for estimating alfalfa quality. Agron. J. 89:952-957.'
      ],
      img: []
    },
    sections: []
  },
};

const navNames = Object.keys(manualPages);

export { manualPages, navNames };


















