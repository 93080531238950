import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { TextField, MenuItem, Box } from '@mui/material';

import { GMT1_ITEMS_OBJ } from '../StaticData/optionsInfo';

const width = 185;

export default function GMT1Options({ options, handleChangeOptions }) {
  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      justifyContent: 'center'
    }}>
      <Box>
        <TextField
          select
          label='Grass Species'
          value={options.species}
          onChange={(e) => handleChangeOptions({species: e.target.value})}
          variant='filled'
          sx={{ width }}
        >
          { GMT1_ITEMS_OBJ.items.map(item => <MenuItem key={uuidv4()} value={item.value}>{item.text}</MenuItem>) }
        </TextField>
      </Box>
      <Box>
        <TextField
          label='Current Canopy Height'
          type='number'
          value={parseFloat(options.currCanopyHeight)}
          onChange={(e) => handleChangeOptions({currCanopyHeight: e.target.value})}
          inputProps={{min: '16', max: '30', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Planned Stubble Height'
          type='number'
          value={parseFloat(options.plannedStubbleHeight)}
          onChange={(e) => handleChangeOptions({plannedStubbleHeight: e.target.value})}
          inputProps={{min: '2', max: '10', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Target NDF'
          type='number'
          value={parseFloat(options.targetNDF)}
          onChange={(e) => handleChangeOptions({targetNDF: e.target.value})}
          inputProps={{min: '38', max: '55', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Estimated Base Yield'
          type='number'
          value={parseFloat(options.estBaseYield)}
          onChange={(e) => handleChangeOptions({estBaseYield: e.target.value})}
          inputProps={{min: '1000', max: '10000', step:'100', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
    </Box>
  );
}


GMT1Options.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
};