import { orchardTable, reedTable, timothyTable } from '../StaticData/dmLossTables';
import roundXDigits from './rounding';


function getTargetNDFLine(targetNDF) {
  let numTNDF = parseFloat(targetNDF);
  
  let data = [];
  for (let i = 12; i <= 30; i++) {
    data.push([i, numTNDF]);
  }

  return { targetNDFLineData: data };
}

function getPredictedNDFLine(species, canopyHeight, stubbleHeight, targetNDF) {
  let harvestNDF = parseFloat(targetNDF) * 10;
  let currGCH = parseFloat(canopyHeight) * 2.54;
  let currGSH = parseFloat(stubbleHeight) * 2.5;
  
  let x,y,z;
  if (species === 'timothy') {
    x = 1.69;
    y = 3.64;
    z = 365;
  } else if (species === 'reed') {
    x = 2.27;
    y = 4.86;
    z = 255;
  } else if (species === 'orchard') {
    x = 2.37;
    y = 4.69;
    z = 286;
  }

  let point = [0,0];
  let lineData = [];
  for (let i = 12; i <= 30; i++) {
    let cm = i * 2.54;
    let pointY = (z + y * cm - x * currGSH) / 10;
    lineData.push([i, pointY]);

    if (String(i) === canopyHeight) {
      point = [[i, pointY]];
    }
  }

  return {
    predictedNDFLineData: lineData,
    currPredictedPoint: point,
    currNDF: (z + y * currGCH - x * currGSH) / 10,
    targetHeight: ((harvestNDF - z + x * currGSH) / y) / 2.54
  };
}

function getBarData(species, targetHeight, plannedStubbleHeight, estBaseYield) {
  if (parseFloat(targetHeight) < 16) {
    return {
      predictedYield: '0',
      yieldLoss: '0'
    };
  }
  
  let table;
  if (species === 'timothy') {
    table = timothyTable;
  } else if (species === 'reed') {
    table = reedTable;
  } else if (species === 'orchard') {
    table = orchardTable;
  }

  let numEBY = parseFloat(estBaseYield);
  let yieldLoss = numEBY * table[roundXDigits(targetHeight, 0)][plannedStubbleHeight];
  let predictedYield = numEBY - yieldLoss;
  
  return {
    predictedYield: predictedYield,
    yieldLoss: yieldLoss
  };
}

export default function updateGMT1GraphData(options) {
  let plData = getPredictedNDFLine(options.species, options.currCanopyHeight, options.plannedStubbleHeight, options.targetNDF);
  let bData = getBarData(options.species, plData.targetHeight, options.plannedStubbleHeight, options.estBaseYield);
  let tlData = getTargetNDFLine(options.targetNDF);

  return {
    ...bData,
    ...plData,
    ...tlData
  };
}