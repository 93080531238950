import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Box, Typography } from '@mui/material';



export default function TextResults({ results }) {
  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 3
    }}>
      {results.map(obj => {
        return (
          <Box key={uuidv4()} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: 'fit-content',
            gap: '6px',
            border: '1px solid rgb(230,230,230)',
            borderRadius: 2,
            padding: '8px 11px'
          }}>
            <Typography variant='optText' sx={{ marginLeft: 0 }}>{obj.title}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{obj.value}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}

TextResults.propTypes = {
  results: PropTypes.array
};