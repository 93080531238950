import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import Title from './Title';

const Card = styled(Box)(() => ({
  margin: '40px auto 20px auto',
  backgroundColor: 'white',
  width: '80%',
  maxWidth: 800,
  padding: '25px 50px',
  border: '1px solid rgb(230,230,230)',
  display: 'flex',
  flexDirection: 'column',
  gap: 20
}));



export default function Welcome() {
  return (
    <Box>
      <Title title='Grass Management Tool Suite' />

      <Card>
        <Typography variant='h3'>Grass Management Tool #1: Grass Only NDF Estimator</Typography>
        <Typography variant='body1'>This tool allows estimation of current NDF and target harvest height for several grass species. The user provides the current grass canopy height, planned stubble height, and target NDF at harvest.</Typography>
        <Typography variant='body2'>Reference: Parsons, D., McRoberts, K., Cherney, J.H., Cherney, D.J.R., Bosworth, S., Jimenez-Serrano, F. 2012. Preharvest neutral detergent fiber concentration of temperate perennial grasses as influenced by stubble height. Crop Sci. (in press).</Typography>
      </Card>

      <Card>
        <Typography variant='h3'>Grass Management Tool #2: Alfalfa-Grass Mixture NDF Estimator</Typography>
        <Typography variant='body1'>This tool allows estimation of current NDF and target alfalfa harvest height for mixed alfalfa-grass stands. The user provides the current alfalfa maximum height (tallest stem) and percent grass in stand. The user can also modify target NDF for the stand, NDF rate of change per day, and also provide a slight adjustment for estimated weather conditions until harvest.</Typography>
        <Typography variant='body2'>Reference: Parsons, D., Cherney, J. H., and Gauch, H. G., Jr. 2006. Estimation of Preharvest Fiber Content of Mixed Alfalfa-Grass Stands in New York. Agron. J. 98:1081-1089.</Typography>
      </Card>

      <Card>
        <Typography variant='h3'>Grass Management Tool #3: Alfalfa Only NDF Estimator</Typography>
        <Typography variant='body1'>This tool allows estimation of current NDF and target harvest height for pure alfalfa. The user provides the current maximum height (tallest stem), planned stubble height, and target NDF at harvest.</Typography>
        <Typography variant='body2'>Reference: Parsons, D., Cherney, J. H., and Gauch, H. G., Jr. 2006. Estimation of spring forage quality for alfalfa in New York State. Online. Forage and Grazinglands doi:10.1094/FG-2006-0323-01-RS.</Typography>
      </Card>

      <Card>
        <Typography variant='h3'>Grass Management For Dairy Cattle</Typography>
        <Typography variant='body1'>This manual deals primarily with cool-season grasses for dairy cattle in the Northeastern USA.</Typography>
      </Card>

      <Card>
        <Typography variant='h3'>Grass Information Sheets</Typography>
        <Typography variant='body1'>A series of 2-page information sheets covering a wide range of grass management issues.</Typography>
      </Card>

      <Card>
        <Typography variant='h3'>Grass-related Factsheets</Typography>
        <Typography variant='body1'>Selected 2-page factsheets from the Cornell Nutrient Management Spear Program Series &apos;Agronomy Factsheets&apos;.</Typography>
      </Card>
    </Box>
  );
}