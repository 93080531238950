import React from 'react';
import PropTypes from 'prop-types';

import { TextField, Box } from '@mui/material';

const width = 185;



export default function GMT1Options({ options, handleChangeOptions }) {
  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      justifyContent: 'center'
    }}>
      <Box>
        <TextField
          label='Current Canopy Height'
          type='number'
          value={options.currCanopyHeight}
          onChange={(e) => handleChangeOptions({currCanopyHeight: e.target.value})}
          inputProps={{min: '16', max: '40', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Planned Stubble Height'
          type='number'
          value={parseInt(options.plannedStubbleHeight)}
          onChange={(e) => handleChangeOptions({plannedStubbleHeight: e.target.value})}
          inputProps={{min: '2', max: '10', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Target NDF'
          type='number'
          value={parseInt(options.targetNDF)}
          onChange={(e) => handleChangeOptions({targetNDF: e.target.value})}
          inputProps={{min: '32', max: '45', step:'1', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
      <Box>
        <TextField
          label='Estimated Base Yield'
          type='number'
          value={parseInt(options.estBaseYield)}
          onChange={(e) => handleChangeOptions({estBaseYield: e.target.value})}
          inputProps={{min: '1000', max: '10000', step:'100', style: { textAlign: 'center' }}}
          variant='filled'
          sx={{ width }}
        />
      </Box>
    </Box>
  );
}


GMT1Options.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
};