import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Typography,
} from '@mui/material';

import ManualSubsection from './ManualSubsection';



export default function ManualSection({ title, content, subsections }) {
  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography variant='h4'>{title}</Typography>
      
      {
        content.map((c, i) => 
          <Typography
            key={'intro' + i}
            variant='body1'
            dangerouslySetInnerHTML={{__html: c}}
            sx={{
              width: '90%',
              margin: '20px auto 10px auto',
              textAlign: 'justify',
              fontSize: '0.82rem',
              '& a': {
                color: 'rgb(170,0,0)',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          ></Typography>
        )
      }

      {
        subsections.map((sect, i) => <ManualSubsection key={sect.title + i} {...sect} />)
      }
    </Box>
  );
}

ManualSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
  subsections: PropTypes.array
};