import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

import OptionsPanel from './OptionsPanel';
import LineGraph from './LineGraph';
import GMT2Options from './GMT2Options';
import GraphPanel from './GraphPanel';
import TextResults from './TextResults';
import TextResultsPanel from './TextResultsPanel';
import Title from './Title';
import CenterPanel from './CenterPanel';



export default function GMT2({ options, handleChangeOptions, token }) {
  let lineGraphOptions = {
    pndf: options.plData,
    tndf: options.tlData,
    cndf: [options.currNDFPoint],
    dth: [options.dthPoint],
    x: {
      title: 'Days Until Harvest',
      min: 0,
      max: options.tlData[options.tlData.length - 1][0] + 1,
      plotLine: 0,
      tickInterval: undefined
    },
    y: {
      min: 15,
      max: undefined,
      tickInterval: undefined
    },
    tooltip: function () {
      let res = this.points.reduce(function (acc, point) {
        if (point.series.name === 'Days to Harvest') {
          return acc;
        }

        return (
          <>
            {acc}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                {point.series.name}:
              </div>
              <div>
                <b>{Math.round(point.y * 2) / 2}%</b>
              </div>
            </div>
          </>);
      }, <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>Days Until Harvest:</div>
          <div><b>{this.points[0].x}</b></div>
        </div>
        <hr/>
      </>);

      return renderToStaticMarkup(<div style={{width: '200px'}}>{res}</div>);
    }
  };

  let lineResults = [
    {
      title: 'Current NDF',
      value: `${Math.round(options.currNDFPoint[1] * 2) / 2}%`
    },
    {
      title: 'Target Alfalfa Harvest Height',
      value: `${options.targetHeight} inches`
    },
    {
      title: 'Approximate Time to Harvest',
      value: `${options.dthPoint[0]} days`
    }
  ];

  if (!options.manualMode && options.bestOutlook !== null) {
    let info = options.bestOutlook.outlookInfo;
    lineResults.push(...[{
      title: 'Outlook Used',
      value: info.name
    },{
      title: 'Normal Temperature Range',
      value: info.range
    },{
      title: 'Most Likely Outlook',
      value: info.text
    }]);
  }


  return (
    <>
      <Title
        title='Grass Management Tool #2'
        subtitle='Alfalfa-Grass Mixture NDF Estimator'
      />

      <CenterPanel>
        <OptionsPanel>
          <GMT2Options
            options={options}
            handleChangeOptions={handleChangeOptions}
            token={token}
          />
        </OptionsPanel>
        <TextResultsPanel>
          <TextResults results={lineResults} />
        </TextResultsPanel>
      </CenterPanel>

      <GraphPanel>
        <LineGraph
          options={lineGraphOptions}
        />
      </GraphPanel>
    </>
  );
}

GMT2.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
  token: PropTypes.string
};