import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography
} from '@mui/material';

import ManualImg from './ManualImg';
import ManualSection from './ManualSection';

import { manualPages } from './gmfdcData';



export default function ManualContent({ chapter }) {
  const cData = manualPages[chapter];
  
  return (
    <Box sx={{
      maxWidth: 900,
      margin: '0 auto',
      padding: '12px',
      boxSizing: 'border-box',
      backgroundColor: 'white',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)',
      height: 'fit-content'
    }}>
      <Typography variant='h2'>{chapter}</Typography>
      {cData.chapter.content.map((c, i) => 
        <Typography
          key={'intro' + i}
          variant='body1'
          dangerouslySetInnerHTML={{__html: c}}
          sx={{
            width: '90%',
            margin: '20px auto 10px auto',
            textAlign: 'justify',
            fontSize: '0.82rem',
            '& a': {
              color: 'rgb(170,0,0)',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }}
        ></Typography>)}
      {cData.chapter.img.map(image => <ManualImg key={image.url} {...image} />)}
      {cData.sections.map(s => <ManualSection key={s.title} {...s} />)}
    </Box>
  );
}

ManualContent.propTypes = {
  chapter: PropTypes.string
};