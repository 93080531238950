import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton
} from '@mui/material';

import { navNames } from './gmfdcData';



export default function ManualNav({ loc }) {
  const navigate = useNavigate();

  return (
    <Box sx={{
      flexShrink: 0,
      width: 200,
      height: 'fit-content',
      backgroundColor: 'white',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)'
    }}>
      <List sx={{ padding: 0 }}>
        {navNames.map((name,i) => {
          return (
            <ListItem key={name} sx={{
              padding: 0
            }}>
              <ListItemButton onClick={() => navigate(`/resources/Grass_Management_For_Dairy_Cattle/${name.replaceAll(' ', '_')}`)} sx={{
                paddingTop: '3px',
                paddingBottom: '3px',
                backgroundColor: name === loc ? 'rgba(0,200,0,0.2)' : 'white',
                '&:hover': {
                  backgroundColor: name === loc ? 'rgba(0,200,0,0.2)' : 'rgba(0,0,0,0.04)',
                }
              }}>
                <Typography sx={{
                  fontSize: '13px'
                }}>{i > 0 ? `${i}. ` : ''}{name}</Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

ManualNav.propTypes = {
  loc: PropTypes.string
};