import React from 'react';
import PropTypes from 'prop-types';

import exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import { Box } from '@mui/material';
highchartsMore(Highcharts); 
exporting(Highcharts);
HC_offline_exporting(Highcharts);
NoDataToDisplay(Highcharts);

import { useTheme } from '@emotion/react';



export default function LineGraph({ options }) {
  const theme = useTheme();

  let series = [
    {
      data: options.pndf,
      name: 'Predicted NDF',
      color: 'rgb(0,80,0)',
      marker: {
        enabled: false
      }
    },
    {
      color: 'rgb(200,0,0)',
      data: options.tndf,
      name: 'Target NDF',
      dashStyle: 'Dash',
      marker: {
        enabled: false
      }
    }
  ];


  let currNDF = {
    name: 'Current NDF',
    type: 'column',
    color: 'rgb(0,180,0)',
    borderWidth: 0,
    data: [{
      x: options.cndf[0][0],
      y: options.cndf[0][1],
      pointWidth: 80,
      color: {
        pattern: {
          image: `${process.env.PUBLIC_URL}/grass4.svg`
        }
      }
    }]
  };


  if (options.dth) {
    let dth = options.dth[0];
    let prevSvg = 2;
    const randSvgNoRepeats = () => {
      let nextSvg = Math.floor(Math.random() * 4) + 1;
      if (nextSvg === prevSvg) {
        nextSvg = nextSvg === 4 ? 1 : nextSvg + 1;
      }

      prevSvg = nextSvg;
      return nextSvg;
    };

    currNDF.centerInCategory = true;
    currNDF.grouping = false;
    currNDF.data = [
      {
        x: options.tndf[0][0],
        y: options.cndf[0][1],
        pointWidth: 0
      },
      {
        x: options.tndf[1][0],
        y: options.cndf[0][1],
        pointWidth: 50,
        color: {
          pattern: {
            image: `${process.env.PUBLIC_URL}/grass4.svg`
          }
        }
      },
      {
        x: options.tndf[2][0],
        y: options.cndf[0][1],
        pointWidth: 50,
        color: {
          pattern: {
            image: `${process.env.PUBLIC_URL}/grass2.svg`
          }
        }
      },
      ...options.tndf.slice(3).map(arr => {
        return {
          x: arr[0],
          y: options.cndf[0][1],
          pointWidth: 50,
          color: {
            pattern: {
              image: `${process.env.PUBLIC_URL}/grass${randSvgNoRepeats()}.svg`
            }
          }
        };
      })
    ];
    
    series.push({
      data: [{
        x: dth[0],
        y: 0
      },{
        x: dth[0],
        y: dth[1] * 0.75,
        dataLabels: {
          enabled: true,
          formatter: () => `${dth[0]} Days <br> to Harvest`,
          borderColor: 'rgb(126,87,14)',
          borderWidth: 2,
          backgroundColor: 'white',
          padding: 5,
          useHTML: true
        }
      },{
        x: dth[0],
        y: dth[1] * 0.85
      }],
      color: 'rgb(126,87,14)',
      lineWidth: 5,
      name: 'Days to Harvest',
      marker: {
        enabled: false
      }
    });
  }
  
  series.push(currNDF);
  
  let opts = {
    exporting:{
      fallbackToExportServer: false,
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadSVG'
          ]
        }
      }
    },

    chart: {
      type: 'line',
      style: {
        fontFamily: theme.typography.fontFamily
      }
    },

    credits: {enabled: false},

    plotOptions: {
      line: {
        lineWidth: 2
      },
      series: {
        states: {
          inactive: {
            opacity: 1
          }
        },
      }
    },

    title: {
      text: '',
    },

    series,

    xAxis: {
      title: {
        text: options.x.title
      },
      min: options.x.min,
      max: options.x.max,
      tickInterval: options.x.tickInterval,
      tickWidth: 2,
      tickColor: 'black',
      lineColor: 'black',
      lineWidth: 2,
      labels: {
        style: {
          color: 'black'
        }
      }
    },

    yAxis: {
      title: {
        text: 'Neutral Detergent Fiber (% NDF)'
      },
      lineWidth: 2,
      lineColor: 'black',
      gridZIndex: 0,
      min: options.y.min,
      max: options.y.max,
      startOnTick: true,
      tickInterval: options.y.tickInterval,
      tickWidth: 2,
      tickColor: 'black',
      labels: {
        style: {
          color: 'black'
        }
      }
    },

    tooltip: {
      formatter: options.tooltip,
      backgroundColor: '#FFFFFF',
      shared: true,
      useHTML: true,
      outside: true
    }
  };


  return (
    <Box sx={{ width: options.dth ? '98%' : '49%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        upDateArgs={[true,true,true]}
        options={opts}
      />
    </Box>
  );
}



LineGraph.propTypes = {
  options: PropTypes.object
};