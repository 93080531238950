import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Box,
  Autocomplete
} from '@mui/material';

export default function Address({ token, handleChangeOptions }) {
  const [address, setAddress] = useState('Ithaca, NY');
  const [timer, setTimer] = useState(null);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Might use onEnter, would be 'enter' => fire this => fire handleChangeAddress
  // const getCoordinates = async (place, token) => {
  //   let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${place.replaceAll(' ', '%20')}.json?proximity=-75.37,43.21&limit=1&access_token=${token}`, { method: 'GET' })
  //     .then(response => response.json())
  //     .then(jData => jData.features[0].center)
  //     .catch(e => {
  //       console.log(e);
  //       return false;
  //     });
  //
  //   return results;
  // };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    (async () => {
      await handleChangeAddress({
        name: 'Ithaca, NY',
        lngLat: [-76.4945, 42.4433]
      });
    })();
  }, []);


  const getOutlooks = async (coords) => {
    const outlooks = {
      '6-10': [`https://www.cpc.ncep.noaa.gov/products/predictions/610day/interactive/includes/get_temps_pie.php?coord=${coords[1]},${coords[0]}&region=conus`, 10],
      '8-14': [`https://www.cpc.ncep.noaa.gov/products/predictions/814day/interactive/includes/get_temps_pie.php?coord=${coords[1]},${coords[0]}&region=conus`, 14],
      '30': [`https://www.cpc.ncep.noaa.gov/products/predictions/long_range/lead14/interactive/includes/get_temps_pie.php?coord=${coords[1]},${coords[0]}&region=conus`, 30],
      '90': [`https://www.cpc.ncep.noaa.gov/products/predictions/long_range/interactive/includes/get_temps_pie.php?coord=${coords[1]},${coords[0]}&region=conus&lead=Lead1`, 90]
    };

    let results = await Promise.all(Object.keys(outlooks).map(async outlookName => {
      let outlookOpts = outlooks[outlookName];
      let res = await fetch(outlookOpts[0], { method: 'GET' });
      res = await res.text();
      let arr = res.split('#').slice(0,5).map(v => parseInt(v));
      return {
        outlookMeta: {
          rangeEnd: outlookOpts[1],
          name: `${outlookName} Day Outlook`
        },
        normal: {
          min: arr[0],
          max: arr[1]
        },
        outlook: {
          above: arr[2],
          below: arr[3],
          near: arr[4]
        }
      };
    }));
  
    return results.sort((a,b) => a.outlookMeta.rangeEnd - b.outlookMeta.rangeEnd);
  };

  const handleChangeAddress = async (newAddress) => {
    if (newAddress) {
      setSelected(newAddress);

      let outlook = await getOutlooks(newAddress.lngLat);
      handleChangeOptions({ weatherConditions: outlook });
    }
  };


  const getGeoCodeOptions = async (place, token) => {
    let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${place.replaceAll(' ', '%20')}.json?proximity=-75.37,43.21&country=US&types=postcode,place,address&access_token=${token}`, { method: 'GET' })
      .then(response => response.json())
      .then(jData => {
        return jData.features.reduce((acc, feat) => {
          if (feat.context.find(c => c.id.includes('region') && c.text === 'New York')) {
            const type = feat.place_type[0];
            let name;
            
            if (type === 'postcode') {
              name = feat.place_name.split(',').slice(0,2).join(',');
            } else if (type === 'place') {
              name = feat.text + ', NY';
            } else if (type === 'address') {
              let city = feat.context.find(c => c.id.includes('place'));
              name = `${feat.text}, ${city.text}, NY`;
            }
  
            acc.push({
              name,
              lngLat: feat.center
            });
          }

          return acc;
        }, []);
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  
    return results;
  };

  const handleTyping = (val) => {
    setAddress(val);

    clearTimeout(timer);

    if (val.length >= 4 && !options.find(opt => opt.name === val) && selected.name !== val) {
      const newTimer = setTimeout(async () => {
        let res = await getGeoCodeOptions(address, token);
        setOptions(res);
      }, 500);
  
      setTimer(newTimer);
    }
  };


  return (
    <Box>
      <Autocomplete
        value={selected}
        onChange={(e,val) => handleChangeAddress(val)}
        renderInput={(params) => <TextField
          {...params}
          size='small'
          label='Location'
          variant='filled'
          sx={{
            paddingTop: '7px',
            '& .MuiFilledInput-input': {
              border: 'none',
              backgroundColor: 'transparent'
            },
            '& .MuiFilledInput-root': {
              backgroundColor: 'transparent !important'
            },
            '& .MuiFilledInput-root input': {
              marginBottom: '2px'
            }
          }}
        />}
        inputValue={address}
        onInputChange={(e,val) => handleTyping(val)}
        sx={{
          width: 200,
          height: 56,
          backgroundColor: 'white',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid rgb(230,230,230)',
          borderBottom: '1px solid black',
          '&:hover': { backgroundColor: 'rgb(248,248,248)' },
          '& .MuiAutocomplete-endAdornment': { top: 'calc(50% - 8px)' }
        }}
        options={[...options, selected]}
        getOptionLabel={option => option.name}
        renderOption={(props, option) => <li {...props} key={option.name} style={{display: 'flex', justifyContent: 'space-between'}}>{option.name}</li> }
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onBlur={() => setOptions([])}
      />
    </Box>
  );
}


Address.propTypes = {
  token: PropTypes.string,
  handleChangeOptions: PropTypes.func
};