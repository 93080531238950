import React from 'react';

import { Box, Typography, List, ListItem } from '@mui/material';

const fsResources = [
  { number: 1, name: 'Soil Sampling for Field Crops' },
  { number: 4, name: 'Nitrogen Credits from Manure' },
  { number: 5, name: 'Soil pH for Field Crops' },
  { number: 6, name: 'Lime Recommendations for Field Crops' },
  { number: 14, name: 'Brown Midrib Sorghum Sudangrass, Part 1' },
  { number: 16, name: 'Application of Manure to Established Alfalfa' },
  { number: 24, name: 'Teff as an Emergency Forage' },
  { number: 26, name: 'Brown Midrib Sorghum Sudangrass Nitrogen Management' },
  { number: 33, name: 'Nutrient Management Planning' },
  { number: 38, name: 'Manure Sampling, Analysis & Interpretation' },
  { number: 42, name: 'Manure Use of Alfalfa-Grass Establishment' },
  { number: 44, name: 'Nitrogen Fertilizers for Field Crops' },
  { number: 45, name: 'Enhanced-Efficiency Nitrogen Sources' },
  { number: 46, name: 'Nitrogen Management of Teff' },
];



export default function GRFactsheets() {
  return (
    <Box sx={{
      width: 525,
      backgroundColor: 'white',
      margin: '0 auto',
      padding: '25px',
      border: '1px solid rgb(180,180,180)',
      boxShadow: '2px 2px 5px 0px rgb(150,150,150)'
    }}>
      <Typography sx={{ fontSize: '20px' }}>Grass-related Factsheets</Typography>
      <Typography sx={{ fontSize: '15px', fontStyle: 'italic' }}>(more available from <a href='http://nmsp.cals.cornell.edu/guidelines/factsheets.html' target='_blank' rel='noreferrer'>Cornell Nutrient Management Spear Program</a>)</Typography>
      <List>
        {fsResources.map(fs => {
          return (
            <ListItem key={fs.name}>
              <a style={{ textDecoration: 'none' }} href={`http://nmsp.cals.cornell.edu/publications/factsheets/factsheet${fs.number}.pdf`} target='_blank' rel='noreferrer'>
                <Box sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    fontWeight: 'bold'
                  }
                }}>
                  FS-{fs.number}: {fs.name}
                </Box>
              </a>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}