import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

import OptionsPanel from './OptionsPanel';
import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import GMT1Options from './GMT1Options';
import GraphPanel from './GraphPanel';
import TextResults from './TextResults';
import TextResultsPanel from './TextResultsPanel';

import roundXDigits from '../Functions/rounding';
import Title from './Title';
import CenterPanel from './CenterPanel';



export default function GMT1({ options, handleChangeOptions }) {
  let lineGraphOptions = {
    pndf: options.predictedNDFLineData,
    tndf: options.targetNDFLineData,
    cndf: options.currPredictedPoint,
    dth: false,
    x: {
      title: 'Grass Height (inches)',
      min: 10,
      max: 31.5,
      plotLine: 10,
      tickInterval: 5
    },
    y: {
      min: 25,
      max: 70,
      tickInterval: 10
    },
    tooltip: function () {
      let res = this.points.reduce(function (acc, point) {
        if (point.color === 'green') return acc;
          
        return (
          <>
            {acc}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                {point.series.name}:
              </div>
              <div>
                <b>{roundXDigits(point.y, 0)}%</b> NDF
              </div>
            </div>
          </>);
      }, <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>Grass Height:</div>
          <div><b>{this.points[0].x}</b> inches</div>
        </div>
        <hr/>
      </>);

      return renderToStaticMarkup(<div style={{width: '200px'}}>{res}</div>);
    }
  };
  
  let lineResults = [
    {
      title: 'Current NDF',
      value: `${roundXDigits(options.currNDF, 0)}%`
    },
    {
      title: 'Target Grass Canopy Harvest Height',
      value: `${roundXDigits(options.targetHeight, 0)} inches`
    }
  ];

  let barResults = [
    {
      title: 'Predicted Yield',
      value: `${roundXDigits(options.predictedYield, 0)} lb/acre`
    },
    {
      title: 'Predicted Yield Loss',
      value: <span>{roundXDigits(options.yieldLoss, 0)} lb/acre &nbsp;&nbsp; {<span style={{ color: 'rgb(180,0,0)' }}>(-{roundXDigits(parseFloat(options.yieldLoss) / parseFloat(options.estBaseYield) * 100, 0)}%)</span>}</span>
    }
  ];
  
  return (
    <>
      <Title
        title='Grass Management Tool #1'
        subtitle='Grass Only NDF Estimator'
      />

      <CenterPanel>
        <OptionsPanel>
          <GMT1Options
            options={options}
            handleChangeOptions={handleChangeOptions}
          />
        </OptionsPanel>
        <TextResultsPanel>
          <TextResults results={lineResults} />
          <TextResults results={barResults} />
        </TextResultsPanel>
      </CenterPanel>

      <GraphPanel>
        <LineGraph
          options={lineGraphOptions}
        />
        <BarGraph
          options={options}
        />
      </GraphPanel>
    </>
  );
}

GMT1.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func
};